.form-page-navbar{
    background: radial-gradient(farthest-corner at 90% 15%, rgba(28, 170, 197), rgba(8, 68, 156) );
    padding: 2rem;
    height:8rem !important;
}

.form-page-brand{
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem !important;
    margin-left: 2.5rem;
}

.menu-style{
    width:2.3rem !important;
    margin-left: 1rem !important;
}
@media (min-width:900px){
    .form-page-navbar{
        background: radial-gradient(farthest-corner at 90% 15%, rgba(20, 132, 182), rgba(13, 94, 166) );;
        padding: 2rem;
        height: 20px;
    }
    
    .form-page-brand{
        margin-top: 5px;
        color: white;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1.7rem ;
        margin-left: 1rem;
    }
    
    .menu-style{
        width:1.5rem;
        margin-left: -0.5rem;
    }
}    
@media (min-width:1024px){
    .form-page-navbar{
        background: radial-gradient(farthest-corner at 90% 15%, rgba(20, 132, 182), rgba(13, 94, 166) );;
        padding: 2rem;
        height: 20px;
    }
    
    .form-page-brand{
        color: white;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1.3rem;
        margin-left: 1rem;
    }
    
    .menu-style{
        width:20px;
        margin-left: -0.5rem;
    }
}

@media (min-width:1280px){
    .form-page-navbar{
        background: radial-gradient(farthest-corner at 90% 15%, rgba(20, 132, 182), rgba(13, 94, 166) );;
        padding: 2rem;
        height: 20px;
    }
    
    .form-page-brand{
        color: white;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1rem;
        margin-left: 1rem;
    }
    
    .menu-style{
        width:20px;
        margin-left: -0.5rem;
    }
}