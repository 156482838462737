@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading {
  position: fixed;
  top: 0; right: 0;
  bottom: 0; left: 0;
  /* background: #fff; */
  background: #ffffffa6;
  z-index: 99999999;
}
.loaders {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: .8em solid rgba(218, 219, 223, 1);
  border-left: .8em solid rgba(58, 166, 165, 1);
  -webkit-animation: spin 1.1s infinite linear;
          animation: spin 1.1s infinite linear;
}
.loaders, .loaders:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

.loading-soal {
  -webkit-animation: fadeIn .7s infinite alternate;
          animation: fadeIn .7s infinite alternate;
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 60%;
  left: 50%;
  margin: -100px 0 0 -150px;
}

@-webkit-keyframes fadeIn { 
  from { opacity: 0; } 
}

@keyframes fadeIn { 
  from { opacity: 0; } 
}

@-webkit-keyframes spin {
0% {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
100% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
}

@keyframes spin {
0% {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
100% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
}
.login-form {
    background-image: url(/static/media/background.8ef01140.png);
    background-color: #005083;
    width: 100%;
    height: 100vh;
    margin: auto;
    background-repeat: no-repeat;
    position: fixed;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}
.username-login, .password-login{
    margin: 10px;
}
.is-danger-login{
    font-size: 1.5rem;
    color:white;
    margin-top: 7px;
}

.title-login p{
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    color: #ffffff;
    font-size: 6.5rem;
    margin-bottom: 0.5rem;
}
.title-login{
    margin-top: 12rem !important;
    margin-left: 11em !important;
}

.form-group-login {
    margin-top: 16em;
    text-align: left;
    margin-left: 10rem;
}

.form-group-login input {
    width: 40rem !important;
    height: 7rem !important;
    padding-left: 2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.7rem !important;
    font-weight: 300;
    color: #0C3265;
    border: none;
}
.form-group-login input::-webkit-input-placeholder {
    color:#005083;
    font-size: 1.8rem !important;
}

.lupa-sandi p {
    color: #ffffff;
    margin-left: 28rem !important;
    margin-top: 0.75rem;
    font-size: 1.6rem;
    opacity: .8;
    font-family: Arial, Helvetica, sans-serif;

}

.login button {
    margin-top: 7rem !important;
    margin-left: 0.5rem !important;
    width: 40rem;
    height: 6rem;
    border: none;
    background-color: #1BAEC9;
    font-size: 2rem;
    color: #ffffff;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border:none;
    border-radius: 10px;
}

.regis p {
    color: #ffffff;
    margin-left: 1rem !important;
    font-size: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 0.75rem;
}

.text-bold{
    font-weight: bold;
}
.form-group-login .invalid-feedback{
    margin-top: 10px;
    margin-left: 0rem;
    font-size: 1.5rem;
    color:white;
    margin-bottom: 1.5rem;
}
.form-group-login .valid-feedback{
    margin-top: 10px;
    margin-left: 0rem;
    font-size: 1.5rem;
    color:white;
    margin-bottom: 1.5rem;
}
@media (min-width:900px){
    .login-form {
        background: url(/static/media/background.8ef01140.png);
        background-color: #005083;
        width: 100%;
        height: 150vh;
        margin-top: -2.5rem !important;
        margin-bottom: 3.5rem;
        position: absolute;
        background-size: cover;
        overflow: auto;
    }
    
    .form-group-login .invalid-feedback{
        margin-top: 10px;
        margin-left: 0rem;
        font-size: 1.5rem;
        color:white;
        margin-bottom: 1.5rem;
    }
    .form-group-login .valid-feedback{
        margin-top: 10px;
        margin-left: 0rem;
        font-size: 1.5rem;
        color:white;
        margin-bottom: 1.5rem;
    }
}
@media (min-width:1024px){

    .login-app {
        background-image: url(/static/media/background.8ef01140.png);
        opacity: 20%;
        width: 100%;
        height: 100vh;
        padding-top: 2.5rem;
        background-repeat: no-repeat;
        position: relative;
        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
    }
    
    .login-form {
        background: url(/static/media/background.8ef01140.png);
        background-color: #005083;
        width: 30rem;
        height: 50rem;
        margin-top: 2rem !important;
        margin-bottom: 3.5rem;
        margin-left:18rem;
        position: absolute;
        background-size: cover;
    }
    .username-login, .password-login{
        margin: 10px;
    }
    .is-danger-login{
        font-size: 12px;
        margin-top: 2px;
        color:white;
        margin-left: -1rem;
    }
    /* .username::-webkit-input-placeholder{
        padding: 1rem;
        color: #005083;
    }
    .password::-webkit-input-placeholder{
        padding: 1rem;
        color: #005083;
    } */
    
    
    .title-login {
      margin-top: 6em !important;
      margin-left: 5em !important;
    }
    .title-login p{
        font-size: 3rem;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: left;
        color: #ffffff;
    }
    
    .form-group-login {
        margin-top: 7rem;
        text-align: left;
        margin-left: 5rem;
    }
    
    .form-group-login input {
        width: 21rem !important;
        height: 3rem !important;
        margin-left: -1rem;
        padding-left: 10px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px !important;
        font-weight: 300;
        color: #0C3265;
        border: none;
        margin-bottom: -8px !important;
    }
    .form-group-login input::-webkit-input-placeholder {
        padding-left: 5px !important;
        color:#005083;
        font-size: 12px !important;
    }
    
    .lupa-sandi p {
        color: #ffffff;
        margin-left: 14.5rem !important;
        margin-top: 0.75rem;
        font-size: 0.75rem;
        opacity: .8;
    
    }
    
    .login button {
        margin-top: 3rem !important;
        margin-left: -0.4rem !important;
        width: 21rem;
        height: 3rem;
        border: none;
        background-color: #1BAEC9;
        font-size: 16px;
        color: #ffffff;
        font-family: Arial, Helvetica, sans-serif;
        border:none;
        border-radius: 5px;
    }
    
    .regis p {
        color: #ffffff;
        margin-left: -0.3rem !important;
        font-size: 11px;
        margin-top: 0.75rem;
    }
    
    .text-bold{
        font-weight: bold;
    }
    .form-group-login .invalid-feedback{
        margin-top: 10px;
        margin-left: -1rem;
        font-size: 14px;
        color:white;
        margin-bottom: 1.5rem;
    }
    .form-group-login .valid-feedback{
        margin-top: 10px;
        margin-left: -1rem;
        font-size: 14px;
        color:white;
        margin-bottom: 1.5rem;
    }
    
    }
@media (min-width:1280px){

.login-app {
    background-image: url(/static/media/background.8ef01140.png);
    opacity: 20%;
    width: 100%;
    height: 100vh;
    padding-top: 2.5rem;
    background-repeat: no-repeat;
    position: fixed;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}

.login-form {
    background: url(/static/media/background.8ef01140.png);
    background-color: #005083;
    width: 24rem;
    height: 44.5rem;
    margin-top: 4rem !important;
    margin-bottom: 3.5rem;
    margin-left:32rem;
    background-size: cover;
}
.username-login, .password-login{
    margin: 10px;
}

.is-danger-login{
    font-size: 12px;
    color:white;
    margin-left: -2rem;
    margin-top: 10px;
}
/* .username::-webkit-input-placeholder{
    padding: 1rem;
    color: #005083;
}
.password::-webkit-input-placeholder{
    padding: 1rem;
    color: #005083;
} */


.title-login {
    margin-top: 4rem !important;
    text-align: left !important;
    margin-left: 4rem !important;
}
.title-login p{
    font-size: 2.8rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    color: #ffffff;
}

.form-group-login {
    margin-top: 5.5rem !important;
}

.form-group-login input {
    width: 17rem !important;
    height: 3rem !important;
    margin-left: -2.2rem;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px !important;
    font-weight: 300;
    color: #0C3265;
    border: none;
    margin-bottom: -8px;
}
.form-group-login input::-webkit-input-placeholder {
    padding-left: 5px;
    color:#005083;
    font-size: 12px !important;
}

.lupa-sandi p {
    color: #ffffff;
    margin-left: 9.5rem !important;
    margin-top: 0.75rem;
    font-size: 0.75rem;
    opacity: .8;

}

.login button {
    margin-top: 1rem !important;
    margin-left: -1.7rem !important;
    width: 17rem;
    height: 3rem;
    border: none;
    background-color: #1BAEC9;
    font-size: 13px;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    border:none;
    border-radius: 5px;
}

.regis p {
    color: #ffffff;
    margin-left: -1.5rem !important;
    font-size: 11px;
    margin-top: 0.75rem;
}

.text-bold{
    font-weight: bold;
}
.form-group-login .invalid-feedback{
    margin-top: 10px;
    margin-left: -2rem;
    font-size: 12px;
    color:white;
}
.form-group-login .valid-feedback{
    margin-top: 10px;
    margin-left: -2rem;
    color:white;
    font-size: 12px;
}
}
.regis-form {
    background-image: url(/static/media/background.8ef01140.png);
    background-color: #005083;
    width: 100%;
    height: 100vh;
    margin: auto;
    background-repeat: no-repeat;
    position: fixed;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}
.title-regis{
    margin-top: 12rem !important;
    margin-left: 11em !important;
}
.form-group-regis .username-regis, .email-regis, .password-regis{
    margin: 10px;
}
.is-danger-regis{
    font-size: 1.5rem;
    color:white;
    margin-left: 0rem;
    margin-top: 7px;
}
.title-regis p{
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    color: #ffffff;
    font-size: 6.5rem;
    margin-bottom: 0.5rem;
}

.form-group-regis {
    margin-top: 12em;
    text-align: left;
    margin-left: 10rem;
}

.form-group-regis input {
    width: 40rem !important;
    height: 7rem;
    padding: 2rem !important;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.7rem !important;
    font-weight: 300;
    color: #0C3265;
    border: none;
    margin-bottom: -5px;
}
.form-group-regis input::-webkit-input-placeholder {
    color:#005083;
    font-size: 1.7rem;
}

.regis .button-regis {
    margin-top: 7rem !important;
    margin-left: 0.3em !important;
    width: 40rem;
    height: 6rem;
    border: none;
    background-color: #1BAEC9;
    font-size: 2rem;
    color: #ffffff;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border:none;
    border-radius: 10px;
}

.login-regis-page p {
    color: #ffffff;
    margin-left: 1rem !important;
    font-size: 1.6rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 0.75rem;

}

.text-bold{
    font-weight: bold;
}

.form-group-regis .invalid-feedback{
    margin-top: 10px;
    margin-left: -2rem;
    color:white;
}
.form-group-regis .valid-feedback{
    margin-top: 10px;
    margin-left: -2rem;
    color:white;
}

@media (min-width:900px){
    .regis-form {
        background-image: url(/static/media/background.8ef01140.png);
        background-color: #005083;
        width: 100%;
        height: 150vh;
        margin-top: -2rem !important;
        position: absolute;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
    }
    .form-group-regis .invalid-feedback{
        margin-top: 10px;
        margin-left: 0rem;
        font-size: 1.2rem;
        color:white;
    }
    .form-group-regis .valid-feedback{
        margin-top: 10px;
        margin-left: 0rem;
        font-size: 1.2rem;
        color:white;
    }
}

@media (min-width:1024px){
    .regis-app {
        background-image: url(/static/media/background.8ef01140.png);
        opacity: 20%;
        width: 100%;
        height: 100vh;
        padding-top: 2.5rem;
        background-repeat: no-repeat;
        position: relative;
        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
    }
    
    .regis-form {
        background-image: url(/static/media/background.8ef01140.png);
        background-color: #005083;
        width: 30rem;
        height: 50rem;
        margin-top: 2rem !important;
        margin-bottom: 3.5rem;
        margin-left:18rem;
        position: absolute;
        background-size: cover;
    }
    
    .title-regis {
        margin-top: 6em !important;
        margin-left: 5em !important;
    }

    .title-regis p{
        font-size: 3rem;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: left;
        color: #ffffff;
    }
    .is-danger-regis{
        font-size: 12px;
        margin-top: 7px;
        color:white;
        margin-left: -1rem;
        margin-bottom: -5px;
    }
    .form-group-regis {
        margin-top: 5rem;
        text-align: left;
        margin-left: 5rem;
    }
    
    .form-group-regis input {
        width: 21rem !important;
        height: 1rem !important;
        margin-left: -1rem;
        padding-left: 10px !important;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px !important;
        font-weight: 300;
        color: #0C3265;
        border: none;
        margin-bottom: -7px;
    }
    .form-group-regis input::-webkit-input-placeholder {
        padding-left: 5px !important;
        color:#005083;
        font-size: 12px !important;
    }
    
    .regis .button-regis {
        margin-top: 3rem !important;
        margin-left: -0.4rem !important;
        width: 21rem;
        height: 3rem;
        border: none;
        background-color: #1BAEC9;
        font-size: 16px;
        color: #ffffff;
        font-family: Arial, Helvetica, sans-serif;
        border:none;
        border-radius: 5px;
    }
    
    .login-regis-page p {
        color: #ffffff;
        margin-left: -0.3rem !important;
        font-size: 11px;
        margin-top: 0.75rem;
    
    }
    .text-bold{
        font-weight: bold;
    }
    .form-group-regis .invalid-feedback{
        margin-top: 10px;
        margin-left: -1rem;
        color:white;
    }
    .form-group-regis .valid-feedback{
        margin-top: 10px;
        margin-left: -1rem !important;
        color:white;
    }
}

@media (min-width:1280px){
    .regis-app {
        background: url(/static/media/background.8ef01140.png);
        opacity: 20%;
        width: 100%;
        height: 100vh;
        padding-top: 2.5rem;
        background-repeat: no-repeat;
        position: fixed;
        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
    }
    
    .regis-form {
        background: url(/static/media/background.8ef01140.png);
        background-color: #005083;  
        width: 24rem;
        height: 46rem;
        margin-top: 4rem !important;
        margin-bottom: 3.5rem;
        margin-left:32rem;
        background-size: cover;
    }
    .is-danger-regis{
        font-size: 12px;
        color:white;
        margin-left: -2rem;
        margin-bottom: -5px;
    }
    .title-regis {
        margin-top: 4rem !important;
        text-align: left !important;
        margin-left: 4rem !important;
    }
    .title-regis p{
        font-size: 2.8rem;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: left;
        color: #ffffff;
    }

    .form-group-regis{
        margin-top: 4rem;
    }
    
    .form-group-regis input {
        width: 17rem !important;
        height: 3rem !important;
        margin-left: -2.2rem !important;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px !important;
        color: #0C3265;
        border: none;
        margin-bottom: -8px !important  ;   
    }

    .form-group-regis input::-webkit-input-placeholder {
        color:#005083;
        font-size: 12px !important;
    }
    
    .regis .button-regis {
        margin-top: 2rem !important;
        margin-left: -1.7rem !important;
        width: 17rem;
        height: 3rem;
        border: none;
        background-color: #1BAEC9;
        font-size: 13px;
        color: #ffffff;
        font-family: Arial, Helvetica, sans-serif;
        border:none;
        border-radius: 5px;
    }
    
    .login-regis-page p {
        color: #ffffff;
        margin-left: -1.5rem !important;
        font-size: 12px;
        margin-top: 0.75rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    .text-bold{
        font-weight: bold;
    }
    .form-group-regis .invalid-feedback{
        margin-top: 10px;
        margin-left: -2rem;
        color:white;
    }
    .form-group-regis .valid-feedback{
        margin-top: 10px;
        margin-left: -2rem;
        color:white;
    }
}
body{
    background-color: #EDF5F8;
}

.form-menu-icon{
    color:white;
    font-size: 3rem;
    position: absolute !important;
    left: 93% !important;
}
/* FORM CONTENT */
.form-page-content{
    font-size: 2.2rem;
    padding-top: 2rem;
    background-color: #EDF5F8;
    height: 115vh;
}

.form-page-content .input-text2{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding: 1.5rem !important;
    margin: 1.5rem auto !important;
    height: 4.5rem !important;
}

.form-page-content .input-double-style{
  margin-left: 0.5rem !important;
}

.form-page-content .input-text2::-webkit-input-placeholder, 
.form-page-content .latitude-input::-webkit-input-placeholder,
.form-page-content .longitude-input::-webkit-input-placeholder,
.form-page-content .input-double::-webkit-input-placeholder,
.form-page-content .input-rt::-webkit-input-placeholder,
.form-page-content .input-rw::-webkit-input-placeholder{
    color : #69B5D2;
    font-style: italic;
    font-size: 1.8rem !important;
}
.form-page-content .input-rt{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 2px solid #69B5D2 !important; 
    border-radius: 7px;
    color : #69B5D2;
    margin: 1.5rem auto;
    width: 29rem !important;
    padding:1.5rem !important;
    height: 4.5rem !important;
}
.form-page-content .input-rw{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 2px solid #69B5D2 !important; 
    border-radius: 7px;
    color : #69B5D2;
    margin:1.5rem 0 0 0.5rem;
    width: 29rem !important;
    padding:1.5rem !important;
    height: 4.5rem !important;
}

.latitude-input{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 2px solid #69B5D2 !important; 
    border-radius: 7px;
    color : #69B5D2;
    margin: 1.5rem 1.8rem auto !important;
    height: 5rem !important;
    width: 16rem !important;
    padding:1.5rem !important;
    position: absolute;
}

.longitude-input{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 2px solid #69B5D2 !important; 
    border-radius: 7px;
    color : #69B5D2;
    margin: 1.5rem 2.1rem auto !important;
    height: 5rem !important;
    width: 16rem !important;
    padding:1.5rem !important;
    position: absolute;
}

.maps-button{
    width: 91.5% !important;
    background-color: #1BAEC9;
    color:white;
    border:none;
    font-size: 2rem !important;
    border-radius: 7px;
    height: 4.8rem;
    margin:2rem auto;
    text-align: center;
    line-height: 4.8rem;
}

 .maps-wrapper .set-location-button{
    width: 20rem !important;
    background-color: #1BAEC9;
    color:white;
    border:none;
    font-size: 2rem !important;
    border-radius: 7px;
    height: 5rem;
    margin: 1.5rem 0rem auto !important;
    text-align: center;
    line-height: 3.8rem;
}
.maps-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 5rem;
}

/* .form-page-content .open-maps .maps-wrapper .form-control.is-valid, .was-validated .form-control:valid{
    border-color:transparent;
    background-image: none;
} */
.form-page-content .form2-button{
    background-color: #1BAEC9;
    color: white;
    padding: 1rem !important;
    margin-top: 3.5rem;
    width: 92%;
    border-radius: 10px !important;
    font-size: 1.9rem !important;
    margin-left: 2.3rem;
}

.form-page-group label{
    display: block !important;
    text-align: left;
    margin-left: 2.5rem;
    font-size: 1.8rem;
}
.form-page-wrapper .label{
    display: block !important;
    text-align: left;
    margin-left: -2.1rem;
    font-size: 1.8rem;
}

.form-page-content .btn2{
    margin-left: 1.2em;

}
.form-page-content .invalid-feedback{
    margin-top: -1rem !important;
    margin-left: -20rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    text-align: center !important;
    
}

.form-page-content .valid-feedback{
    margin-top: -1rem !important;
    margin-left: -23.5rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    text-align: center !important;
}

.form-page-wrapper .invalid-feedback{
    margin-top: -1rem !important;
    margin-left: -5.5rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    text-align: center !important;
}


.form-page-wrapper .valid-feedback{
    margin-top: 2rem !important;
    margin-left: -10rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    text-align: center;
}

.form-page-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 2.1rem;
    margin-right: 1.4rem;
}
.form-page-group select{
    font-size: 1.9rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding-left: 1.5rem !important;
    margin: 1.5rem auto;
    margin-left: 2.5rem;
    height: 4.3rem !important;
    width: 56.5rem;
}
.form-page-group select option{
    font-size: 0.9rem;
}
.form-kk .input-select option::-webkit-input-placeholder{
    font-size: 1.7rem !important;
    color : #69B5D2;
    font-style: italic;
}

.first-option::-webkit-input-placeholder{
    font-style: italic;
}

.first-option::-moz-placeholder{
    font-style: italic;
}

.first-option:-ms-input-placeholder{
    font-style: italic;
}

.first-option::-ms-input-placeholder{
    font-style: italic;
}

.first-option::placeholder{
    font-style: italic;
}
.open-maps{
    width: 90%;
    margin: 3rem auto;

}

@media (min-width:900px){
    .form-page-content{
        font-size: 1.3rem;
        /* padding: 2rem 8rem 0 8rem; */
        background-color: #EDF5F8;
        height: 110vh;
    }
    
    .form-page-content .input-text{
        font-size: 1.3rem;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 7px;
        color : #69B5D2;
        padding: 1.5rem;
        width: 92%;
    }
    .form-page-content .input-text2{
        font-size: 1.3rem;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 7px;
        color : #69B5D2;
        padding: 1.5rem;
        width: 92%;
    }
    
    .form-page-content .input-text::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic;
        font-size: 1.2rem;
    }
    
    .form-page-content .input-text2::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic;
        font-size: 1.2rem;
    }
    
    .form-page-content button{
        background-color: #1BAEC9;
        color: white;
        padding: 0.5rem;
        margin-top: 2rem;
        width: 92%;
        border-radius: 5px;
        font-size: 1.2rem;
    }
    
    .form-page-content label{
        display: block !important;
        margin-bottom: -1rem !important;
        margin-left: 2.8rem;
    }
    
    .form-page-content .btn2{
        margin-left: 4%;
    }
    .form-page-content .invalid-feedback{
        margin-top: -1rem;
        font-size: 14px;
        margin-left: -23.5rem;
        margin-bottom: 1.5rem;
    }
    .form-page-content .valid-feedback{
        margin-top: -1rem;
        font-size: 14px;
        margin-left: -25.5rem;
        margin-bottom: 1.5rem;
        
    }
}

@media(min-width:1024px){
    .form-page-content .invalid-feedback{
        margin-top: -1rem;
        font-size: 1.3rem !important;
        margin-left: -22.5rem !important;
        margin-bottom: 1.5rem;
    }
    .form-page-wrapper .invalid-feedback{
        margin-top: -1rem;
        margin-left: -7.1rem !important;
        font-size: 1.3rem !important;
        margin-bottom: 1.5rem;
    }
    .form-page-wrapper .valid-feedback{
        margin-top: -1rem;
        margin-left: -10rem !important;
        font-size: 1.3rem !important;
        margin-bottom: 1.5rem;
    }
}
@media (min-width:1280px){
    .form-page-content{
        width: 40%;
        font-size: 1rem !important;
        padding : 2rem 0 0 2rem;
        background-color: #EDF5F8;
        height: 105vh !important;
        margin: 2.8rem auto;
        border-radius: 10px;
        box-shadow: 0px 5px 20px 2px rgba(173, 171, 171, 0.8);
    }
    
    .form-page-content .input-text{
        font-size: 14px !important;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 3px;
        color : #69B5D2;
        padding: 1rem !important;
        width: 100% !important;
        margin-left: 0rem;
        margin-right: -5rem;
    }
    .form-page-content .input-text2{
        font-size: 14px;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 3px;
        color : #69B5D2;
        padding: 1rem !important;
        width: 120%;
        margin-left: 0rem;
        margin-right: -5rem;
    }

    .form-page-content .input-text::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic; 
        font-size:14px !important;
    }
    .form-page-content .input-text2::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic; 
        font-size:12px;
    }
    .form-page-content .input-double{
        font-size: 14px !important;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2 !important; 
        border-radius: 3px !important;
        color : #69B5D2;
        margin: 1.5rem auto;
        width: 15.2rem !important;
        padding:1rem !important;
        margin-left: -2rem;
    }
    .form-page-content .input-double::-webkit-input-placeholder{
        font-size: 14px !important;
        color : #69B5D2;
        font-style: italic;
    }
    
    
    .form-page-content button{
        background-color: #1BAEC9;
        color: white;
        padding: 0.5rem !important;
        margin-top: 1.5rem;
        margin-left: -2.2rem !important;
        width: 92%;
        border-radius: 5px;
        font-size: 15px !important;
    }
    
    .form-page-content label{
        text-align: left;
        margin-left: 0rem;
        margin-bottom: -1rem;
        font-size: 1rem;
    }
    .form-page-content .btn2{
        width: 92%;
        margin-left: 0rem !important;
    }

    .form-page-group{
        text-align: left;
    }
    .form-page-content .invalid-feedback{
        margin-top: -1rem;
        margin-left: 0rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }
    .form-page-content .valid-feedback{
        margin-top: -1rem;
        margin-left: 0rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }
    .form-page-wrapper .invalid-feedback{
        margin-top: -1rem;
        margin-left: -4.1rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }

    .form-page-wrapper .input-control .invalid-feedback{
        margin-top: -1rem;
        margin-left: -5rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }
    .form-page-wrapper .valid-feedback{
        margin-top: -1rem;
        margin-left: -6.2rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }
    .form-page-wrapper .input-control .valid-feedback{
        margin-top: -1rem;
        margin-left: -7rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }
    
}

/* RESPONSIVE */

/* DROPDOWN NAVBAR */
.household-dropdown .dropdown-menu{
    width: 15rem !important;
    padding: 18px 0 0 22px;
    font-size: 1.7rem !important;
    border:none;
    border-radius: 10px;
    height: 8rem;
    font-family: Helvetica, sans-serif;
    position: absolute;
    left: 21rem !important;
    top: -1.8rem !important;
}

.household-dropdown .menu-icon{
    color:white;
    font-size: 4rem;
    margin-left: 38rem;
    position: absolute;
    top: -1.5rem !important;
    left: -4rem !important;
  }
body{
    background-color: #EDF5F8;
}


/* FORM CONTENT */
.form-page-content2{
    font-size: 2.2rem;
    padding-top: 2rem;
    background-color: #EDF5F8;
    height: 115vh;
}

.form-page-content2 .input-text2{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding: 2.5rem;
    margin: 1.5rem auto;
}

.form-page-content2 .input-text2::-webkit-input-placeholder{
    color : #69B5D2;
    font-style: italic;
    font-size: 1.7rem !important;
}

.form-page-content2 button{
    background-color: #1BAEC9;
    color: white;
    padding: 1rem !important;
    margin-top: 3.5rem;
    width: 92%;
    border-radius: 10px;
    font-size: 2rem !important;
}

.form-page-content2 label{
    display: block !important;
    text-align: left;
    margin-left: 2.5rem;
    font-size: 1.8rem !important;
}

.form-page-content2 .btn2{
    margin-left: 1.2em;

}
.form-page-content2 .invalid-feedback{
    margin-top: -1rem;
    margin-left: -20.4rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem;
    text-align: center;
}
.form-page-content2 .valid-feedback{
    margin-top: -1rem !important;
    margin-left: -23.5rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    text-align: center;
}
@media (min-width:900px){
    .form-page-content2{
        font-size: 1.3rem;
        /* padding: 2rem 8rem 0 8rem; */
        background-color: #EDF5F8;
        height: 110vh;
    }
    
    .form-page-content2 .input-text{
        font-size: 1.3rem;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 7px;
        color : #69B5D2;
        padding: 1.5rem;
        width: 92%;
    }
    .form-page-content2 .input-text2{
        font-size: 1.3rem;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 7px;
        color : #69B5D2;
        padding: 1.5rem;
        width: 92%;
    }
    
    .form-page-content2 .input-text::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic;
        font-size: 1.2rem;
    }
    
    .form-page-content2 .input-text2::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic;
        font-size: 1.2rem;
    }
    
    .form-page-content2 button{
        background-color: #1BAEC9;
        color: white;
        padding: 0.5rem;
        margin-top: 2rem;
        width: 92%;
        border-radius: 5px;
        font-size: 1.2rem;
    }
    
    .form-page-content2 label{
        display: block !important;
        margin-bottom: -1rem !important;
        margin-left: 2.8rem;
    }
    
    .form-page-content2 .btn2{
        margin-left: 4%;
    }
    /* .form-page-content2 .invalid-feedback{
        margin-top: -1rem;
        font-size: 14px;
        margin-left: -23.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
    
    }
    .form-page-content2 .valid-feedback{
        margin-top: -1rem;
        font-size: 14px;
        margin-left: -25.5rem;
        margin-bottom: 1.5rem;
    } */
}

@media (min-width:1280px){
    .form-page-content2{
        width: 40%;
        font-size: 1rem;
        padding : 2rem 0 0 2rem;
        background-color: #EDF5F8;
        height: 105vh !important;
        margin: 2.8rem auto;
        border-radius: 10px;
        box-shadow: 0px 5px 20px 2px rgba(173, 171, 171, 0.8);
    }
    
    .form-page-content2 .input-text{
        font-size: 14px;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 3px;
        color : #69B5D2;
        padding: 1rem !important;
        width: 120%;
        margin-left: 0rem;
        margin-right: -5rem;
    }
    .form-page-content2 .input-text2{
        font-size: 14px;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 3px;
        color : #69B5D2;
        padding: 1rem !important;
        width: 120%;
        margin-left: 0rem;
        margin-right: -5rem;
    }


    /* .invalid-feedback{
        margin-top: -1rem;
        margin-bottom: 1.5rem;
        text-align: center !important;
        margin-left: -8.75rem;
        color: red;
    } */
    
    .form-page-content2 .input-text::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic; 
        font-size:14px !important;
    }
    .form-page-content2 .input-text2::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic; 
        font-size:14px !important;
    }
    
    .form-page-content2 button{
        background-color: #1BAEC9;
        color: white;
        padding: 0.5rem;
        margin-top: 1.5rem;
        margin-left: -2.2rem !important;
        width: 92%;
        border-radius: 5px;
        font-size: 15px;
    }
    
    .form-page-content2 label{
        text-align: left;
        margin-left: 0rem;
        margin-bottom: -1rem;
        font-size: 1rem;
    }
    .form-page-content2 .btn2{
        width: 92%;
        margin-left: 0rem !important;
    }

    .form-page-group{
        text-align: left;
    }
    .form-page-content2 .invalid-feedback{
        margin-top: -1rem;
        margin-left: 0rem !important;
        font-size: 12px;
        margin-bottom: 1.5rem;
    }
    .form-page-content2 .valid-feedback{
        margin-top: -1rem;
        margin-left: 0rem !important;
        font-size: 12px;
        margin-bottom: 1.5rem;
    }
    
}

/* RESPONSIVE */



.data-edit-icon{
    color:white;
    font-size: 2rem;
    margin-left: 28rem
}

.data-trash-icon{
    color:white;
    font-size: 2rem;
    margin-left: 2rem;
}

.data-table{
    width: 90% !important;
    margin:auto;
    margin-top: 5rem;
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    text-align: left;
}

.custom-table{
    margin-top: 5rem !important;
}

.data-table td{
    padding-left: 3rem !important;
}

.devided-line{
    width: 90%;
}

.survey-result{
    font-weight: bold;
    font-size: 3rem;
    margin-left: 3rem;
    font-family: 'Roboto', sans-serif;
}
.data-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.detail-data .progress{
    width: 85% !important;
    margin-left: 3rem !important;
}
.percentage{
    font-size: 2.5rem;
    font-family: 'Roboto', sans-serif;
    margin-top: -1.5rem;
}

.survey-button{
    width: 23rem;
    background-color: #1BAEC9;
    color:white;
    border:none;
    font-size: 2.5rem;
    border-radius: 10px;
    height: 8rem;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    line-height: 8rem;
    margin-left: 3rem;
}



/* 
.swal-text{
    font-size: 1.3rem !important;
}

.swal-icon{
    width: 8rem !important;
    height: 8rem !important;
    
}

.swal-icon--warning__body{
    width: 5px !important;
    height: 5rem !important;
    top: 16px !important;
} */
.swal-title{
    font-size: 2.3rem !important;
}

.swal-modal{
    margin-top: -7rem;
}
body{
    font-family: helvetica;
}.navbar-biodata{
    background: -webkit-radial-gradient(90% 15%, farthest-corner, rgba(28, 170, 197), rgba(8, 68, 156) );
    background: radial-gradient(farthest-corner at 90% 15%, rgba(28, 170, 197), rgba(8, 68, 156) );
    height:8rem !important;
}


.swal-modal{
    zoom: 1.7 !important;;
}


/* 
.swal-title{
    font-size: 4.5rem;
    text-align: left;
    color:#069B00;
}
.swal-title:first-child{
    margin-top: 3rem;
}

.swal-text{
    font-size: 1.8rem;
    text-align: left !important;
    font-weight: bold;
    margin-left: -22rem;
} */
.brand-biodata{
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.9rem !important;
    margin-left: 2rem;
}
.biodata-menu-icon{
    color:white;
    font-size: 3rem;
    position: absolute;
    left: 3.5rem;
    top:-1rem !important;
}

.bio-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    margin-bottom: 1rem;
}

.Nokk{
    font-weight: bolder;
    font-size: 2.5rem;
}

.edit-icon{
    font-size: 2.8rem;
    color:#2AACCB;
    margin-left: 22rem;
}
.bio-wrap1{
    width: 30rem;   
}
.bio-name{
    font-size: 2.2rem;
    font-weight: 600;
}

.bio-address{
    font-size: 1.6rem;
    margin-bottom: 3rem;
}

.survei-keluarga{
    font-weight: bold;
    font-size: 2.3rem;
    margin-top: 3rem;
}
.presentase{
    font-size: 1.8rem;
    font-weight: bold;
    margin-left: 1rem;
    margin-top: -1rem;
}

.bio-start{
    width: 23rem;
    background-color: #1BAEC9;
    color:white;
    border:none;
    font-size: 2.3rem;
    border-radius: 10px;
    height: 8rem;
    position: absolute;
    left:35.5rem;
    top:32rem;
}

.bio-wrapper2{
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.bio-wrapper3{
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.anggota-keluarga{
    font-weight: bold;
    font-size: 2.3rem;
    margin-top: 1rem;
}

.tambah-baru{
    width: 20rem;
    background-color: #069B00;
    color:white;
    border:none;
    font-size: 1.5rem;
    border-radius: 10px;
    height: 4.8rem; 
    margin-left: 7.5rem !important;
}

.tambah-baru-icon{
    width:2.8rem;
    margin-right: 1rem;
}

.biodata-body{
    margin:3rem;
}
.progress{
    width: 20rem !important;
    height: 13px !important;
    background-color: #C4C4C4;
    border-radius: 13px;
    margin-left: 0rem !important;
}
.progress-bar{
    background-color: #1BAEC9;
}

hr{
    width: 100%;
    border: 1px solid #C4C4C4;
}

.biodata-body .table{
  width: 61rem;
  margin-left: -3rem;
  margin-right: -1rem;
  font-size: 1.3rem;
}

.biodata-body .react-bootstrap-table{
  margin-top: 5rem !important; 
}

.biodata-body thead{
  font-size: 1.8rem;
  background-color: #B0DCED;
  padding: 5px;
}

.table-striped tbody tr:nth-of-type(odd){
  background-color: #DCEEF5;
}

.survei-button{
  font-size: 23px;
  width: 115px;
  background-color: #1BAEC9;
  border-radius: 8px;
  border:none;
  color: white;
  padding: 9px;
  margin-left: 0rem;
}

.navbar-biodata .dropdown-menu{
  position: absolute;
  width: 13rem;
  top:-1.5rem;
  left:23.5rem;
  padding: 18px 0 0 22px;
  font-size: 1.6rem;
  border:none;
  border-radius: 10px;
  height: 8rem;
  font-family: Helvetica;
}
.navbar-kk{
    background: -webkit-radial-gradient(90% 15%, farthest-corner, rgba(20, 132, 182), rgba(13, 94, 166) );
    background: radial-gradient(farthest-corner at 90% 15%, rgba(20, 132, 182), rgba(13, 94, 166) );;
    padding: 2rem;
    height:8rem !important;
}

.brand-kk{
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem !important;
    margin-left: 2rem;
}
.form-kk{
    font-size: 2.2rem;
    padding-top: 2rem;
    background-color: #EDF5F8;
    height: 115vh;
}
.form-kk .input-kk{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding: 1.5rem !important;
    margin: 1.5rem auto;
    height: 4.3rem !important;
}

.form-group-kk select{
    font-size: 1.9rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding-left: 1.5rem !important;
    margin: 1.5rem auto;
    margin-left: 2.5rem;
    height: 4.3rem !important;
    width: 56.5rem;
}

.form-group-kk select option{
    font-size: 0.9rem;
}

.form-kk .birthdate-label{
    margin-top: 3rem !important;
}
.form-kk .react-datepicker-wrapper input{
    margin: 1rem 0 3rem 2.5rem !important;
    border-radius: 7px !important;
    border : 3px solid #69B5D2; 
    background-color: #EDF5F8;
    font-size: 1.8rem !important;
    color : #69B5D2 !important;
    width: 56rem !important;
    height: 4.3rem !important;
    font-style: italic;
    padding: 20px !important;
}

.form-kk .r-input{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding: 1.5rem !important;
    margin: 1.5rem auto;
    height: 4.3rem !important;
    width: 56.5rem;
    margin-left: 2.5rem;
}
.form-kk .input-kk::-webkit-input-placeholder{
    font-size: 1.7rem !important;
    color : #69B5D2;
    font-style: italic;
}
.form-kk .input-select option::-webkit-input-placeholder{
    font-size: 1.7rem !important;
    color : #69B5D2;
    font-style: italic;
}
.form-kk .r-input::-webkit-input-placeholder{
    font-size: 1.7rem !important;
    color : #69B5D2;
    font-style: italic;
}
.form-kk label{
    display: block !important;
    text-align: left;
    margin-left: 2.5rem;
    font-size: 1.8rem;
}

.form-kk .react-datepicker-popper{
    position: static;
    left: 4% !important;
    top: -38rem !important;

}

.form-kk .react-datepicker__triangle{
   display: none !important;
}

.form-kk .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    font-size: 1.8rem !important;
  }
  
.form-kk .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    font-size: 23px !important;
    width: 3.5rem !important;
    height: 3.5rem !important;
    line-height: 2.3rem !important;
  } 
  
.form-kk .form-button{
    background-color: #1BAEC9;
    color: white;
    padding: 1rem !important;
    margin-top: 3.5rem;
    width: 92%;
    border-radius: 10px !important;
    font-size: 1.9rem !important;
    margin-left: 2.3rem;
}
.kk-button-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.kk-button-wrapper .male-button{
    width: 28rem;
    border:none;
    padding:1rem !important;
    margin-left: 2.5rem;
    border-radius: 5px;
}
.kk-button-wrapper .female-button{
    width: 28rem;
    border:none;
    padding:1rem !important;
    margin-left: 0.3rem;
    border-radius: 5px; 
}
.left-icon{
    color:white;
    font-size: 2.5rem;
}

.form-menu-icon{
    color:white;
    font-size: 3rem;
    position: absolute;
    left: 93%;
}

.form-group-kk .valid-feedback{
    margin-left:-23.2rem;
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 1rem;
}
.form-group-kk .invalid-feedback{
    margin-left:-19rem;
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 1rem;
}

.birthdate .invalid-feedback{
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 1rem;
    margin-left: -13rem !important;
}

.male{
    width: 27rem;
    height: 5rem;
    font-size: 1.9rem;
    line-height: 5rem;
    text-align: center;
    border-radius: 10px;
    margin-left: 2.5rem;

}
.female{
    width: 27rem;
    height: 5rem;
    font-size: 1.9rem;
    line-height: 5rem;
    text-align: center;
    border-radius: 10px;
    margin-left: 1rem;
}

.male label, .female label{
    text-align: center;
}
.male input[type=checkbox], .female input[type=checkbox], .male input[type=radio], .female input[type=radio]{
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
}

.select-selector{
    margin:1rem auto 2rem auto;
    width: 56.5rem;
    border-radius: 7px;
    background-color: black !important;
}

.css-1g48xl4-IndicatorsContainer{
    opacity: 0;
}

.css-1uccc91-singleValue{
    color: #69B5D2 !important;
    font-size: 1.9rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.css-m8q1j8-control{
    border-width: 3px !important;
    outline: none;
}

.css-1sflke2-menu{
    font-size: 1.9rem;
}

/* NAVBAR DROPDOWN */

.data-individu .data-individu-dropdown .dropdown-menu{
    width: 15rem !important;
    margin-top: -5rem !important;
    margin-left: 26rem !important;
    padding: 18px 0 0 22px;
    font-size: 1.7rem !important;
    border:none;
    border-radius: 10px;
    height: 8rem;
    font-family: Helvetica, sans-serif;
}

.data-individu .data-individu-dropdown .menu-icon{
    color:white;
    font-size: 4rem;
    margin-left: 38rem;
  }
/* Position and sizing of burger button */
/* .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  } */
  
  /* Color/shape of burger icon bars */
  /* .bm-burger-bars {
    background: #373a47;
  } */
  
  /* Color/shape of burger icon bars on hover*/
  /* .bm-burger-bars-hover {
    background: #a90000;
  }
   */
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  .close-button{
    width: 40px !important;
    float:right !important;
    margin-top: -7% !important;
    margin-right: 3% !important;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed !important;
    height: 100% !important;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #1277B0;
    font-size: 2em !important;
    width: 45vh !important;
    height: 110% !important;
    padding-top: 5rem !important;
    padding-left: 10rem !important;
    margin-top: -10rem !important;
    margin-left: -8rem !important;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: white;
    padding: 0.8em !important;
    text-align: left;
    font-size: 2rem !important;
  }
  
  /* Individual item */
  /* .bm-item {
    display: inline-block;
    color: white;
    font-family: 'PT Sans', sans-serif;
    font-size: 25px;
    line-height: 1.5em;
  } */
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    margin-top: -4em !important;
    margin-left: -2em;
  }

  .list-options{
      color: white !important;
      font-family: 'PT Sans', sans-serif !important;
      font-size: 2.1rem !important;
      line-height: 2.3em !important;
      display: block !important;
      text-align: left;
  }
  .child-options{
    color: white !important;
    font-family: 'PT Sans', sans-serif !important;
    font-size: 2rem !important;
    line-height: 1.8em;
    display: block;
    text-align: left;
    margin-left: 8%;
}

  .sidebar-title{
    color: white;
    text-align: left;
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .sidebar-menu .title-underline{
    width: 85%;
    border-bottom: 3px solid white;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .logout{
    font-size: 2rem !important;
    color: white;
    text-align: left !important;
    position: absolute !important;
    top: 50% !important;
  }

  @media (min-width:900px){
    .bm-menu-wrap {
      position: fixed;
      margin-left: -2.3rem;
      margin-top: 4vh;
    }
    
    /* General sidebar styles */
    .bm-menu {
      background: #1277B0;
      /* padding: 2.5em 1.5em 0; */
      font-size: 1.15em;
      width: 65%;
      height: 104vh;
      margin-left: 0.1rem;
      padding-left: 1.5rem;
      padding-top: 3rem;
    }
    .list-options{
      color: white;
      font-family: 'PT Sans', sans-serif;
      font-size: 1.3rem;
      line-height: 2em;
      display: inline-block;
      text-align: left;
  }
  .list-options:hover{
    color: white;
}
  .list-options2{
    color: white;
    font-family: 'PT Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 1.8em;
    display: inline-block;
    text-align: left;
    margin-left: 8%;
}
.list-options2:hover{
  color: white;
}

  .sidebar-title{
    color: white;
    text-align: left;
    font-size: 2rem;
  }

  .sidebar-menu .title-underline{
    width: 80%;
    border-bottom: 1px solid white;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .logout{
    font-size: 1.2rem;
    color: white;
    margin-top: 35vh;
    text-align: left;
  }
  .logout:hover{
    color: midnightblue;
    text-decoration: none;
  }
  .close-button{
    width: 30px;
    float:right;
    margin-top: -6%;
  }
  } 
  @media (min-width:1024px){
    /* Position and sizing of burger button */
    /* .bm-burger-button {
        position: fixed;
        width: 36px;
        height: 30px;
        left: 36px;
        top: 36px;
      } */
      
      /* Color/shape of burger icon bars */
      /* .bm-burger-bars {
        background: #373a47;
      } */
      
      /* Color/shape of burger icon bars on hover*/
      /* .bm-burger-bars-hover {
        background: #a90000;
      }
       */
      /* Position and sizing of clickable cross button */
      .bm-cross-button {
        height: 24px;
        width: 24px;
      }
      
      /* Color/shape of close button cross */
      .bm-cross {
        background: #bdc3c7;
      }
    
      .close-button{
        width: 30px;
        float:right;
        margin-top: -6%;
      }
      
      /*
      Sidebar wrapper styles
      Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
      */
      .bm-menu-wrap {
        position: fixed;
        margin-left: -2.3rem;
        margin-top: 4vh;
      }
      
      /* General sidebar styles */
      .bm-menu {
        background: #1277B0;
        /* padding: 2.5em 1.5em 0; */
        font-size: 1.15em;
        width: 65%;
        height: 104vh !important;
        margin-left: 0.1rem;
        padding-left: 2rem;
        padding-top: 4rem;
      }
      
      /* Morph shape necessary with bubble or elastic */
      .bm-morph-shape {
        fill: #373a47;
      }
      
      /* Wrapper for item list */
      /* .bm-item-list {
        color: white;
        padding: 0.8em;
        text-align: left;
      } */
      
      /* Individual item */
      /* .bm-item {
        display: inline-block;
        color: white;
        font-family: 'PT Sans', sans-serif;
        font-size: 25px;
        line-height: 1.5em;
      } */
      
      /* Styling of overlay */
      .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
        height: 102vh !important;
      }
    
      .list-options{
          color: white;
          font-family: 'PT Sans', sans-serif;
          font-size: 1.3rem;
          line-height: 2em;
          display: inline-block;
          text-align: left;
      }
      .list-options:hover{
        color: white;
    }
      .list-options2{
        color: white;
        font-family: 'PT Sans', sans-serif;
        font-size: 1.2rem;
        line-height: 1.8em;
        display: inline-block;
        text-align: left;
        margin-left: 8%;
    }
    .list-options2:hover{
      color: white;
    }
    
      .sidebar-title{
        color: white;
        text-align: left;
        font-size: 2rem;
      }
    
      .sidebar-menu .title-underline{
        width: 80%;
        border-bottom: 1px solid white;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    
      .logout{
        font-size: 1.2rem;
        color: white;
        margin-top: 35vh;
        text-align: left;
      }
      .logout:hover{
        color: midnightblue;
        text-decoration: none;
      }
    
      }
    

  @media (min-width:1280px){
/* Position and sizing of burger button */
/* .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  } */
  
  /* Color/shape of burger icon bars */
  /* .bm-burger-bars {
    background: #373a47;
  } */
  
  /* Color/shape of burger icon bars on hover*/
  /* .bm-burger-bars-hover {
    background: #a90000;
  }
   */
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  .close-button{
    width: 30px;
    float:right;
    margin-top: -7%;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    margin-left: -2.3rem;
    margin-top: 6vh;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #1277B0;
    /* padding: 2.5em 1.5em 0; */
    font-size: 1.15em;
    width: 45% !important;
    height: 105vh !important;
    margin-left: 0.1rem;
    padding-left: 1rem;
    padding-top: 6rem;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  /* .bm-item-list {
    color: white;
    padding: 0.8em;
    text-align: left;
  } */
  
  /* Individual item */
  /* .bm-item {
    display: inline-block;
    color: white;
    font-family: 'PT Sans', sans-serif;
    font-size: 25px;
    line-height: 1.5em;
  } */
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    height: 102vh !important;
  }

  .list-options{
      color: white;
      font-family: 'PT Sans', sans-serif;
      font-size: 1.3rem;
      line-height: 1.8em;
      display: inline-block;
      text-align: left;
  }
  .list-options:hover{
    color: white;
}
  .list-options2{
    color: white;
    font-family: 'PT Sans', sans-serif;
    font-size: 1.1rem;
    line-height: 1.8em;
    display: inline-block;
    text-align: left;
    margin-left: 8%;
}
.list-options2:hover{
  color: white;
}

  .sidebar-title{
    color: white;
    text-align: left;
    font-size: 1.8rem;
  }

  .sidebar-menu .title-underline{
    width: 75%;
    border-bottom: 3px solid white;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .logout{
    font-size: 16px;
    color: white;
    margin-top: 52vh;
    text-align: left;
  }
  .logout:hover{
    color: midnightblue;
    text-decoration: none;
  }

  }

.form-page-navbar{
    background: -webkit-radial-gradient(90% 15%, farthest-corner, rgba(28, 170, 197), rgba(8, 68, 156) );
    background: radial-gradient(farthest-corner at 90% 15%, rgba(28, 170, 197), rgba(8, 68, 156) );
    padding: 2rem;
    height:8rem !important;
}

.form-page-brand{
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem !important;
    margin-left: 2.5rem;
}

.menu-style{
    width:2.3rem !important;
    margin-left: 1rem !important;
}
@media (min-width:900px){
    .form-page-navbar{
        background: -webkit-radial-gradient(90% 15%, farthest-corner, rgba(20, 132, 182), rgba(13, 94, 166) );
        background: radial-gradient(farthest-corner at 90% 15%, rgba(20, 132, 182), rgba(13, 94, 166) );;
        padding: 2rem;
        height: 20px;
    }
    
    .form-page-brand{
        margin-top: 5px;
        color: white;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1.7rem ;
        margin-left: 1rem;
    }
    
    .menu-style{
        width:1.5rem;
        margin-left: -0.5rem;
    }
}    
@media (min-width:1024px){
    .form-page-navbar{
        background: -webkit-radial-gradient(90% 15%, farthest-corner, rgba(20, 132, 182), rgba(13, 94, 166) );
        background: radial-gradient(farthest-corner at 90% 15%, rgba(20, 132, 182), rgba(13, 94, 166) );;
        padding: 2rem;
        height: 20px;
    }
    
    .form-page-brand{
        color: white;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1.3rem;
        margin-left: 1rem;
    }
    
    .menu-style{
        width:20px;
        margin-left: -0.5rem;
    }
}

@media (min-width:1280px){
    .form-page-navbar{
        background: -webkit-radial-gradient(90% 15%, farthest-corner, rgba(20, 132, 182), rgba(13, 94, 166) );
        background: radial-gradient(farthest-corner at 90% 15%, rgba(20, 132, 182), rgba(13, 94, 166) );;
        padding: 2rem;
        height: 20px;
    }
    
    .form-page-brand{
        color: white;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1rem;
        margin-left: 1rem;
    }
    
    .menu-style{
        width:20px;
        margin-left: -0.5rem;
    }
}
.loader{
  margin-top: 80%;
  margin-left: 23%;
}
.question-title{
    font-size: 2rem !important;
    font-weight: bold;
    text-align: left !important;
    margin-left: 1.5rem !important;
    margin-top: 1rem !important;
}
.single-question{
  margin-bottom: 2rem !important;
  display: block;
}

.single-button-wrapper{
  /* position: absolute; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-left: 2rem !important;
  text-align: left;
  font-size: 1.5rem;
}

.single-question .single-options{
  margin-bottom: 2rem !important;
}
.question-text{
  margin-left: 2rem !important;
  margin-right: 3rem;
  font-size: 2.2rem !important;
  text-align: left;
}

.choice-wrap{
  text-align: left;
  margin-left: 1rem !important;
}

.choice-answer{
  font-size: 2rem;
  color:black;
  margin-left: 3rem !important;
}

.single-button-wrapper [type="radio"]:checked,
.single-button-wrapper [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.single-button-wrapper [type="radio"]:checked + label,
.single-button-wrapper [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    /* line-height: 20px; */
    display: inline-block;
    color: #666;
}
.single-button-wrapper [type="radio"]:checked + label:before,
.single-button-wrapper [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: -3rem !important;
    top: 0.5rem !important;
    width: 3rem;
    height: 3rem;
    border: 2px solid #999;
    border-radius: 100%;
    background: #fff;
    scale: 2;
}
.single-button-wrapper [type="radio"]:checked + label:after,
.single-button-wrapper [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 1.7rem !important;
    height: 1.7rem !important;
    background: #1BAEC9;
    position: absolute;
    top: 1.1rem !important;
    left: -2.3rem !important;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.single-button-wrapper [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.single-button-wrapper [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.multi-question{
  margin-bottom: 4rem !important;
  margin-top: 2rem !important;
  display: block !important;
}

.multi-wrapper{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-left: 2rem !important;
  text-align: left;
  font-size: 1.5rem;
}
.multi-question .checkbox-style{
  text-align: left;
  margin-left: -0.5rem !important;
}
  /* Checkbox Style */
.checkbox-button {
    cursor: pointer;
  }
  .checkbox-button3 {
    cursor: pointer;
  }
  
.choice-map input[type=checkbox] {
      box-sizing: border-box;
      padding: 0;
  }
  
.choice-map input {
      font-size: 1rem;
      line-height: 1.5;
      padding: 11px 23px;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 0;
      outline: 0;
      background-color: transparent;
  }
  
  .checkbox-button__input {
      opacity: 0;
      position: absolute;
  }
  .checkbox-button__label{
      font-size: 2.1rem;
  }
  
  .checkbox-button__control {
      position: relative;
      display: inline-block;
      width: 55px;
      height: 55px;
      vertical-align: middle;
      background-color: inherit;
      color: #1BAEC9;
      border: 2px solid #666;
      border-radius: 10px;
  }
  
  .checkbox-button__input:checked+.checkbox-button__control:after {
      content: "";
      display: block;
      position: absolute;
      top: 12px;
      left: 12px;
      width: 28px;
      height: 28px;
      background-color: #1BAEC9;
      border-radius: 5px;
  }
  
  .checkbox-button__input:checked+.checkbox-button__control {
      border-color: #1BAEC9;
  }
  
  .checkbox-button__control {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }
  
.numeric-question{
    margin-bottom: 6rem !important;
    display: block;
}
.question-text{
    margin-left: 2rem !important;
    margin-right: 3rem;
    font-size: 2.2rem !important;
    text-align: left;
}
.in-decrement{
    margin-top: -1rem !important;
    margin-left: -3rem !important;
    margin-bottom: -7rem;
}
.btn-in-decrement{
    width: 60px !important;
    height: 60px !important;
    background-color: #1BAEC9;
    border:none !important;
    border-radius: 50% !important;
    line-height: 10px;
    padding-bottom: 10px !important;
    font-size: 2rem;
    color: white;
    font-weight: bold;
    display: inline-block;
}
.in-decrement input{
    width: 9rem !important;
    height: 3.5rem !important;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem !important;
    text-align: center;
    font-size: 2.3rem !important;
    border: 2px solid #1BAEC9;
}

.numeric-question .button-wrapper{
    /* position: absolute; */
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 5rem !important;
    text-align: left;
    font-size: 1.5rem;
}
.choice-wrap{
    text-align: left;
    margin-left: -1.5rem !important;
  }

.string-question{
    margin-bottom: 4rem !important;
    margin-top: 2rem !important;
    display: block !important;
}
.question-text{
    margin-left: 2rem !important;
    margin-right: 3rem;
    font-size: 2.2rem !important;
    text-align: left;
}
.string-question .string-wrap{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-left: 2rem !important;
  text-align: left;
  font-size: 1.5rem;
  }
  .string-choice{
      font-size: 2rem !important;
  }

  .string-field{
    border: none;
    border-bottom: 3px solid blue;
  }

  .string-field:focus{
    width: 10vh;
  }
  .container {
    width: 200px;
    position: relative;
   }
  .string-question input[type="text"] { 
    width: 30vh; 
    margin-left: -1rem;
    font-size: 1.8rem !important;
    font-family: helvetica;
  }
   .string-question .input {
    border: 0;
    padding: 10px 0;
    border-bottom: 5px solid #ccc;
   }
   .string-question .input ~ .border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #08449C;
   }
   .string-question .input:focus ~ .border {
    width: 30vh;
    -webkit-transition: 0.5s;
    transition: 0.5s;
   }
.date-question{
  margin-bottom: 4rem !important;
  margin-top: 2rem !important;
  display: block !important;
}

.date-question .react-datepicker-popper{
  position: static;
  left: 0% !important;
  top: 1rem !important;
} 

.react-datepicker__triangle{
  display: none !important;
}

.date-wrapper{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-left: 2rem !important;
  text-align: left;
  font-size: 1.5rem;
}

.date-question .react-datepicker-wrapper input{
  padding: 1rem;
  font-size: 2rem !important;
  border: 1px solid black;
  width: 13rem;
}

/* DATE PICKER */

.date-question .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  font-size: 1.8rem !important;
}

.date-question .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  font-size: 23px !important;
  width: 3.3rem !important;
  height: 3.3rem !important;
  line-height: 2.3rem !important;
} 

.question-container{
  width: 880px !important;
  height: 81% !important;
  background-color: white;
  box-shadow: 0px 5px 100px 1px rgba(100, 142, 209, 0.5);
  border-radius: 1rem;
  position: absolute !important;
  top:15% !important;
  left:5% !important;
  font-size: 2.7rem;
  text-align: center;
  -webkit-animation: animasiBox .5s;
          animation: animasiBox .5s;
  margin: auto;
  padding-top:1rem !important;
  overflow: auto;
  padding-top: 8rem !important;
}

.icon-quote{
  width: 12rem;
  position: absolute;
  top: 10% !important;
  left: 10%;
  -webkit-animation: shake 2s;
          animation: shake 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.question-text{
  margin-left: 2rem;
  margin-right: 3rem;
  font-size: 2.2rem !important;
  text-align: left;
}
.button-wrapper .choice-wrap{
  text-align: left;
  margin-left: 1rem !important;
}

.choice-answer{
  font-size: 2rem !important;
}

.question-button{
  margin-left: 18rem;
  margin-top: -3rem;
  margin-bottom: 3rem;
}
.prev-button{
  width: 15rem;
  margin-right: 1rem;
  background-color: #1BAEC9;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  font-size: 2rem;
  border: none;
  box-shadow: 0px 5px 20px 2px rgba(173, 171, 171, 0.8);

}
.next-button{
  width: 15rem;
  border: none;
  background-color: #1BAEC9;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  font-size: 2rem;
  box-shadow: 0px 5px 20px 2px rgba(173, 171, 171, 0.8);
}

@-webkit-keyframes shake {
  0% {
      -webkit-transform: translate(1px, 1px) rotate(-5deg);
              transform: translate(1px, 1px) rotate(-5deg);
  }

  50% {
      -webkit-transform: translate(-5px, -5px) rotate(10deg);
              transform: translate(-5px, -5px) rotate(10deg);
  }

  100% {
      -webkit-transform: translate(1px, 1px) rotate(-5deg);
              transform: translate(1px, 1px) rotate(-5deg);
  }
}

@keyframes shake {
  0% {
      -webkit-transform: translate(1px, 1px) rotate(-5deg);
              transform: translate(1px, 1px) rotate(-5deg);
  }

  50% {
      -webkit-transform: translate(-5px, -5px) rotate(10deg);
              transform: translate(-5px, -5px) rotate(10deg);
  }

  100% {
      -webkit-transform: translate(1px, 1px) rotate(-5deg);
              transform: translate(1px, 1px) rotate(-5deg);
  }
}
.question-title{
    font-size: 2rem !important;
    font-weight: bold;
    text-align: left !important;
}
.question-container{
    width: 880px !important;
    height: 81% !important;
    background-color: white;
    box-shadow: 0px 5px 100px 1px rgba(100, 142, 209, 0.5);
    border-radius: 1rem;
    position: absolute !important;
    top:15% !important;
    left:5% !important;
    font-size: 2.7rem;
    text-align: center;
    -webkit-animation: animasiBox .5s;
            animation: animasiBox .5s;
    margin: auto;
    padding-top:1rem !important;
    overflow: auto;
    padding-top: 8rem !important;
  }
  
  .icon-quote{
    width: 12rem;
    position: absolute;
    top: 10% !important;
    left: 10%;
    -webkit-animation: shake 2s;
            animation: shake 2s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  
  .question-text{
    margin-left: 2rem;
    margin-right: 3rem;
    font-size: 2.2rem !important;
    text-align: left;
  }
  .button-wrapper .choice-wrap{
    text-align: left;
    margin-left: 1rem !important;
  }
  
  .choice-answer{
    font-size: 2rem !important;
  }
  
  .question-button{
    margin-left: 18rem;
    margin-top: -3rem;
    margin-bottom: 3rem;
  }
  .prev-button{
    width: 15rem;
    margin-right: 1rem;
    background-color: #1BAEC9;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    font-size: 2rem;
    border: none;
    box-shadow: 0px 5px 20px 2px rgba(173, 171, 171, 0.8);
  
  }
  .next-button{
    width: 15rem;
    border: none;
    background-color: #1BAEC9;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    font-size: 2rem;
    box-shadow: 0px 5px 20px 2px rgba(173, 171, 171, 0.8);
  }
  @-webkit-keyframes shake {
    0% {
        -webkit-transform: translate(1px, 1px) rotate(-5deg);
                transform: translate(1px, 1px) rotate(-5deg);
    }
  
    50% {
        -webkit-transform: translate(-5px, -5px) rotate(10deg);
                transform: translate(-5px, -5px) rotate(10deg);
    }
  
    100% {
        -webkit-transform: translate(1px, 1px) rotate(-5deg);
                transform: translate(1px, 1px) rotate(-5deg);
    }
  }
  @keyframes shake {
    0% {
        -webkit-transform: translate(1px, 1px) rotate(-5deg);
                transform: translate(1px, 1px) rotate(-5deg);
    }
  
    50% {
        -webkit-transform: translate(-5px, -5px) rotate(10deg);
                transform: translate(-5px, -5px) rotate(10deg);
    }
  
    100% {
        -webkit-transform: translate(1px, 1px) rotate(-5deg);
                transform: translate(1px, 1px) rotate(-5deg);
    }
  }

  .table-body .react-bootstrap-table{
    margin-top: -6rem !important;
}
.table-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    color:white;
    grid-gap: 2rem;
    margin-left: 2.5rem;
    margin-top: 3rem;
}

.table-wrapper p{
    font-size: 1.9rem;
    font-family: helvetica;
}
.table-body{
    background-color: white;
    height: 100%;
    position: relative;
}
.table{
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    margin-top: -1.5rem;
    font-family: helvetica;
}

.table td{
    width: 250px;
    border:none;
    outline: none;
}


.edit-button{
    font-size: 23px;
    width: 115px;
    background-color: #1BAEC9;
    border-radius: 8px;
    border:none;
    color: white;
    padding: 9px;
    margin-left: 6rem;
}

.head-table{
    margin-top: 0rem !important;
    width: 100% !important;
}

.head-table tr td{
    font-size: 1.5rem;
}

.button-tambah{
    width: 150px;
    height: 150px;
    position: absolute;
    top:87%;
    left: 81%;
    position: fixed;
    background-color: #005083;
    color: white;
    font-size: 6rem;
    border:none;
    border-radius: 50%;
}
.button-text{
    margin-top: 0rem;
}

.dropdown-toggle::after{
    display: none;
    background: transparent;
}

.btn-primary{
    background: transparent;
    border:none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    background: transparent;
    border:none;
}


.row{
  margin-right: 0px !important
}
.row .react-bootstrap-table-pagination{
  width: 100%;
}

.pagination{
  font-size: 2.2rem !important;
  float: right !important;
  margin-right: 2rem !important;
}

.page-link{
  color: #005083
}

.pagination .dropdown-toggle{
  font-size: 1.5rem !important;
  margin-left: 2.5rem !important;
  margin-right: 1rem !important;
}

.react-bootstrap-table-pagination-total{
  font-size: 1.5rem !important;
  margin-top: 1rem !important;
}

.col-md-6 .col-xs-6 .col-sm-6 .col-lg-6{
  padding-right: 0px !important;
}

.btn-secondary{
    width: 3.8rem !important;
    height: 3.8rem !important;
    font-size: 1.8rem !important;
    margin-left: 2.5rem !important;
    margin-right: 1rem !important;
}

.table-body .dropdown-menu{
    width: 15rem !important;
    margin-top: -17rem !important;
    margin-left: 6rem !important;
    font-size: 2rem !important;
    
}
.dropdown-item{
    color: #005083;
}


.table-navbar{
    background: -webkit-radial-gradient(90% 15%, farthest-corner, rgba(28, 170, 197), rgba(8, 68, 156) );
    background: radial-gradient(farthest-corner at 90% 15%, rgba(28, 170, 197), rgba(8, 68, 156) );
    height:13rem !important;
    font-family: helvetica;
}

.table-brand{
    color: white;
    font-size: 1.9rem !important;
    font-family: helvetica;
}

.navbar-body  .wrap3 .dropdown-menu{
    width: 15rem !important;
    margin-top: -5rem !important;
    margin-left: 1.5rem !important;
    padding: 18px 0 0 22px;
    font-size: 1.7rem !important;
    border:none;
    border-radius: 10px;
    height: 8rem;
    font-family: Helvetica, sans-serif;
}
.search-icon{
    color:white;
    font-size: 2.5rem;
    position:absolute;
    left: 53rem;
    top: 3.3rem;
}
.menu-icon{
  color:white;
  font-size: 4rem;
  margin-left: 14rem;
}

.swal-title{
    font-size: 1.5rem !important;
}
.swal-text{
    font-size: 1.3rem !important;
}
.search-label{
    width: 33rem !important;
    position: absolute !important;
    left: 23rem !important;
    top: 2.5rem !important;
}

.search-label .form-control{
    font-size: 1.8rem !important;
    border-radius: 5rem !important;
    font-style: italic !important;
    padding: 2.2rem !important;
    border: none !important;
    background: rgba(255, 255, 255, .5);
    margin-left: 1rem !important;
  }
  
  .search-label input::-webkit-input-placeholderl{
    color:white !important;
  }
.wrap1{
    width: 18rem;
    margin-top: 0.5rem !important;
}
.wrap3{
  margin-top: -1rem !important;
  margin-left: 0rem !important;
}
.wrap5{
    margin-left: 2rem;
    width: 15rem !important;
}
.wrap6{
    margin-left: 7rem;
}

.head-table{
    margin-top: 0rem !important;
    width: 100% !important;
}

.head-table tr td{
    font-size: 1.5rem;
}

.last-page-body{
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 40%;
}
.caption-text{
    color : #0C3265;
    font-size: 3rem;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
}

.btn-last-page{
    background-color: #1BAEC9;
    color: white;
    padding: 1.5rem;
    margin-top: 4rem;
    width: 60%;
    border-radius: 1rem;
    border:none;
    font-size: 2.5rem;
    font-family: Helvetica, sans-serif;
}

@-webkit-keyframes animasiGambar{
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
      }
      50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
      }
      100%{
          -webkit-transform: scale(1);
                  transform: scale(1);
      }
}

@keyframes animasiGambar{
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
      }
      50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
      }
      100%{
          -webkit-transform: scale(1);
                  transform: scale(1);
      }
}
@media (min-width:900px){
    .last-page-body{
        width: 100%;
        height: 100vh;
        text-align: center;
        padding-top: 3rem;
    }
    .caption-text{
        color : #0C3265;
        font-size: 2rem;
        font-family: Helvetica, sans-serif;
        font-weight: bold;
    }
    
    .btn-last-page{
        background-color: #1BAEC9;
        color: white;
        width: 20rem;
        border-radius: 5px;
        font-size: 1rem;
        font-family: Helvetica, sans-serif;
        border:none;
        margin-top: 1rem !important;
    }
    
    @-webkit-keyframes animasiGambar{
        0% {
            -webkit-transform: scale(0);
                    transform: scale(0);
          }
          50% {
            -webkit-transform: scale(1.2);
                    transform: scale(1.2);
          }
          100%{
              -webkit-transform: scale(1);
                      transform: scale(1);
          }
    }
    
    @keyframes animasiGambar{
        0% {
            -webkit-transform: scale(0);
                    transform: scale(0);
          }
          50% {
            -webkit-transform: scale(1.2);
                    transform: scale(1.2);
          }
          100%{
              -webkit-transform: scale(1);
                      transform: scale(1);
          }
    }
}
