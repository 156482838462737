.last-page-body{
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 40%;
}
.caption-text{
    color : #0C3265;
    font-size: 3rem;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
}

.btn-last-page{
    background-color: #1BAEC9;
    color: white;
    padding: 1.5rem;
    margin-top: 4rem;
    width: 60%;
    border-radius: 1rem;
    border:none;
    font-size: 2.5rem;
    font-family: Helvetica, sans-serif;
}

@keyframes animasiGambar{
    0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1.2);
      }
      100%{
          transform: scale(1);
      }
}
@media (min-width:900px){
    .last-page-body{
        width: 100%;
        height: 100vh;
        text-align: center;
        padding-top: 3rem;
    }
    .caption-text{
        color : #0C3265;
        font-size: 2rem;
        font-family: Helvetica, sans-serif;
        font-weight: bold;
    }
    
    .btn-last-page{
        background-color: #1BAEC9;
        color: white;
        width: 20rem;
        border-radius: 5px;
        font-size: 1rem;
        font-family: Helvetica, sans-serif;
        border:none;
        margin-top: 1rem !important;
    }
    
    @keyframes animasiGambar{
        0% {
            transform: scale(0);
          }
          50% {
            transform: scale(1.2);
          }
          100%{
              transform: scale(1);
          }
    }
}