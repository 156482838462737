
  .table-body .react-bootstrap-table{
    margin-top: -6rem !important;
}
.table-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    color:white;
    grid-gap: 2rem;
    margin-left: 2.5rem;
    margin-top: 3rem;
}

.table-wrapper p{
    font-size: 1.9rem;
    font-family: helvetica;
}
.table-body{
    background-color: white;
    height: 100%;
    position: relative;
}
.table{
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    margin-top: -1.5rem;
    font-family: helvetica;
}

.table td{
    width: 250px;
    border:none;
    outline: none;
}


.edit-button{
    font-size: 23px;
    width: 115px;
    background-color: #1BAEC9;
    border-radius: 8px;
    border:none;
    color: white;
    padding: 9px;
    margin-left: 6rem;
}

.head-table{
    margin-top: 0rem !important;
    width: 100% !important;
}

.head-table tr td{
    font-size: 1.5rem;
}

.button-tambah{
    width: 150px;
    height: 150px;
    position: absolute;
    top:87%;
    left: 81%;
    position: fixed;
    background-color: #005083;
    color: white;
    font-size: 6rem;
    border:none;
    border-radius: 50%;
}
.button-text{
    margin-top: 0rem;
}

.dropdown-toggle::after{
    display: none;
    background: transparent;
}

.btn-primary{
    background: transparent;
    border:none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    background: transparent;
    border:none;
}


.row{
  margin-right: 0px !important
}
.row .react-bootstrap-table-pagination{
  width: 100%;
}

.pagination{
  font-size: 2.2rem !important;
  float: right !important;
  margin-right: 2rem !important;
}

.page-link{
  color: #005083
}

.pagination .dropdown-toggle{
  font-size: 1.5rem !important;
  margin-left: 2.5rem !important;
  margin-right: 1rem !important;
}

.react-bootstrap-table-pagination-total{
  font-size: 1.5rem !important;
  margin-top: 1rem !important;
}

.col-md-6 .col-xs-6 .col-sm-6 .col-lg-6{
  padding-right: 0px !important;
}

.btn-secondary{
    width: 3.8rem !important;
    height: 3.8rem !important;
    font-size: 1.8rem !important;
    margin-left: 2.5rem !important;
    margin-right: 1rem !important;
}

.table-body .dropdown-menu{
    width: 15rem !important;
    margin-top: -17rem !important;
    margin-left: 6rem !important;
    font-size: 2rem !important;
    
}
.dropdown-item{
    color: #005083;
}
