.multi-question{
  margin-bottom: 4rem !important;
  margin-top: 2rem !important;
  display: block !important;
}

.multi-wrapper{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-left: 2rem !important;
  text-align: left;
  font-size: 1.5rem;
}
.multi-question .checkbox-style{
  text-align: left;
  margin-left: -0.5rem !important;
}
  /* Checkbox Style */
.checkbox-button {
    cursor: pointer;
  }
  .checkbox-button3 {
    cursor: pointer;
  }
  
.choice-map input[type=checkbox] {
      box-sizing: border-box;
      padding: 0;
  }
  
.choice-map input {
      font-size: 1rem;
      line-height: 1.5;
      padding: 11px 23px;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 0;
      outline: 0;
      background-color: transparent;
  }
  
  .checkbox-button__input {
      opacity: 0;
      position: absolute;
  }
  .checkbox-button__label{
      font-size: 2.1rem;
  }
  
  .checkbox-button__control {
      position: relative;
      display: inline-block;
      width: 55px;
      height: 55px;
      vertical-align: middle;
      background-color: inherit;
      color: #1BAEC9;
      border: 2px solid #666;
      border-radius: 10px;
  }
  
  .checkbox-button__input:checked+.checkbox-button__control:after {
      content: "";
      display: block;
      position: absolute;
      top: 12px;
      left: 12px;
      width: 28px;
      height: 28px;
      background-color: #1BAEC9;
      border-radius: 5px;
  }
  
  .checkbox-button__input:checked+.checkbox-button__control {
      border-color: #1BAEC9;
  }
  
  .checkbox-button__control {
    transform: scale(0.75);
  }
  