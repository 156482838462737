.navbar-kk{
    background: radial-gradient(farthest-corner at 90% 15%, rgba(20, 132, 182), rgba(13, 94, 166) );;
    padding: 2rem;
    height:8rem !important;
}

.brand-kk{
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem !important;
    margin-left: 2rem;
}
.form-kk{
    font-size: 2.2rem;
    padding-top: 2rem;
    background-color: #EDF5F8;
    height: 115vh;
}
.form-kk .input-kk{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding: 1.5rem !important;
    margin: 1.5rem auto;
    height: 4.3rem !important;
}

.form-group-kk select{
    font-size: 1.9rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding-left: 1.5rem !important;
    margin: 1.5rem auto;
    margin-left: 2.5rem;
    height: 4.3rem !important;
    width: 56.5rem;
}

.form-group-kk select option{
    font-size: 0.9rem;
}

.form-kk .birthdate-label{
    margin-top: 3rem !important;
}
.form-kk .react-datepicker-wrapper input{
    margin: 1rem 0 3rem 2.5rem !important;
    border-radius: 7px !important;
    border : 3px solid #69B5D2; 
    background-color: #EDF5F8;
    font-size: 1.8rem !important;
    color : #69B5D2 !important;
    width: 56rem !important;
    height: 4.3rem !important;
    font-style: italic;
    padding: 20px !important;
}

.form-kk .r-input{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding: 1.5rem !important;
    margin: 1.5rem auto;
    height: 4.3rem !important;
    width: 56.5rem;
    margin-left: 2.5rem;
}
.form-kk .input-kk::-webkit-input-placeholder{
    font-size: 1.7rem !important;
    color : #69B5D2;
    font-style: italic;
}
.form-kk .input-select option::-webkit-input-placeholder{
    font-size: 1.7rem !important;
    color : #69B5D2;
    font-style: italic;
}
.form-kk .r-input::-webkit-input-placeholder{
    font-size: 1.7rem !important;
    color : #69B5D2;
    font-style: italic;
}
.form-kk label{
    display: block !important;
    text-align: left;
    margin-left: 2.5rem;
    font-size: 1.8rem;
}

.form-kk .react-datepicker-popper{
    position: static;
    left: 4% !important;
    top: -38rem !important;

}

.form-kk .react-datepicker__triangle{
   display: none !important;
}

.form-kk .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    font-size: 1.8rem !important;
  }
  
.form-kk .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    font-size: 23px !important;
    width: 3.5rem !important;
    height: 3.5rem !important;
    line-height: 2.3rem !important;
  } 
  
.form-kk .form-button{
    background-color: #1BAEC9;
    color: white;
    padding: 1rem !important;
    margin-top: 3.5rem;
    width: 92%;
    border-radius: 10px !important;
    font-size: 1.9rem !important;
    margin-left: 2.3rem;
}
.kk-button-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.kk-button-wrapper .male-button{
    width: 28rem;
    border:none;
    padding:1rem !important;
    margin-left: 2.5rem;
    border-radius: 5px;
}
.kk-button-wrapper .female-button{
    width: 28rem;
    border:none;
    padding:1rem !important;
    margin-left: 0.3rem;
    border-radius: 5px; 
}
.left-icon{
    color:white;
    font-size: 2.5rem;
}

.form-menu-icon{
    color:white;
    font-size: 3rem;
    position: absolute;
    left: 93%;
}

.form-group-kk .valid-feedback{
    margin-left:-23.2rem;
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 1rem;
}
.form-group-kk .invalid-feedback{
    margin-left:-19rem;
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 1rem;
}

.birthdate .invalid-feedback{
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 1rem;
    margin-left: -13rem !important;
}

.male{
    width: 27rem;
    height: 5rem;
    font-size: 1.9rem;
    line-height: 5rem;
    text-align: center;
    border-radius: 10px;
    margin-left: 2.5rem;

}
.female{
    width: 27rem;
    height: 5rem;
    font-size: 1.9rem;
    line-height: 5rem;
    text-align: center;
    border-radius: 10px;
    margin-left: 1rem;
}

.male label, .female label{
    text-align: center;
}
.male input[type=checkbox], .female input[type=checkbox], .male input[type=radio], .female input[type=radio]{
    appearance: none !important;
}

.select-selector{
    margin:1rem auto 2rem auto;
    width: 56.5rem;
    border-radius: 7px;
    background-color: black !important;
}

.css-1g48xl4-IndicatorsContainer{
    opacity: 0;
}

.css-1uccc91-singleValue{
    color: #69B5D2 !important;
    font-size: 1.9rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.css-m8q1j8-control{
    border-width: 3px !important;
    outline: none;
}

.css-1sflke2-menu{
    font-size: 1.9rem;
}

/* NAVBAR DROPDOWN */

.data-individu .data-individu-dropdown .dropdown-menu{
    width: 15rem !important;
    margin-top: -5rem !important;
    margin-left: 26rem !important;
    padding: 18px 0 0 22px;
    font-size: 1.7rem !important;
    border:none;
    border-radius: 10px;
    height: 8rem;
    font-family: Helvetica, sans-serif;
}

.data-individu .data-individu-dropdown .menu-icon{
    color:white;
    font-size: 4rem;
    margin-left: 38rem;
  }