.question-container{
    width: 880px !important;
    height: 81% !important;
    background-color: white;
    box-shadow: 0px 5px 100px 1px rgba(100, 142, 209, 0.5);
    border-radius: 1rem;
    position: absolute !important;
    top:15% !important;
    left:5% !important;
    font-size: 2.7rem;
    text-align: center;
    animation: animasiBox .5s;
    margin: auto;
    padding-top:1rem !important;
    overflow: auto;
    padding-top: 8rem !important;
  }
  
  .icon-quote{
    width: 12rem;
    position: absolute;
    top: 10% !important;
    left: 10%;
    animation: shake 2s;
    animation-iteration-count: infinite;
  }
  
  .question-text{
    margin-left: 2rem;
    margin-right: 3rem;
    font-size: 2.2rem !important;
    text-align: left;
  }
  .button-wrapper .choice-wrap{
    text-align: left;
    margin-left: 1rem !important;
  }
  
  .choice-answer{
    font-size: 2rem !important;
  }
  
  .question-button{
    margin-left: 18rem;
    margin-top: -3rem;
    margin-bottom: 3rem;
  }
  .prev-button{
    width: 15rem;
    margin-right: 1rem;
    background-color: #1BAEC9;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    font-size: 2rem;
    border: none;
    box-shadow: 0px 5px 20px 2px rgba(173, 171, 171, 0.8);
  
  }
  .next-button{
    width: 15rem;
    border: none;
    background-color: #1BAEC9;
    color: white;
    padding: 1rem;
    border-radius: 10px;
    font-size: 2rem;
    box-shadow: 0px 5px 20px 2px rgba(173, 171, 171, 0.8);
  }
  @keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(-5deg);
    }
  
    50% {
        transform: translate(-5px, -5px) rotate(10deg);
    }
  
    100% {
        transform: translate(1px, 1px) rotate(-5deg);
    }
  }