body{
    background-color: #EDF5F8;
}

.form-menu-icon{
    color:white;
    font-size: 3rem;
    position: absolute !important;
    left: 93% !important;
}
/* FORM CONTENT */
.form-page-content{
    font-size: 2.2rem;
    padding-top: 2rem;
    background-color: #EDF5F8;
    height: 115vh;
}

.form-page-content .input-text2{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding: 1.5rem !important;
    margin: 1.5rem auto !important;
    height: 4.5rem !important;
}

.form-page-content .input-double-style{
  margin-left: 0.5rem !important;
}

.form-page-content .input-text2::-webkit-input-placeholder, 
.form-page-content .latitude-input::-webkit-input-placeholder,
.form-page-content .longitude-input::-webkit-input-placeholder,
.form-page-content .input-double::-webkit-input-placeholder,
.form-page-content .input-rt::-webkit-input-placeholder,
.form-page-content .input-rw::-webkit-input-placeholder{
    color : #69B5D2;
    font-style: italic;
    font-size: 1.8rem !important;
}
.form-page-content .input-rt{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 2px solid #69B5D2 !important; 
    border-radius: 7px;
    color : #69B5D2;
    margin: 1.5rem auto;
    width: 29rem !important;
    padding:1.5rem !important;
    height: 4.5rem !important;
}
.form-page-content .input-rw{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 2px solid #69B5D2 !important; 
    border-radius: 7px;
    color : #69B5D2;
    margin:1.5rem 0 0 0.5rem;
    width: 29rem !important;
    padding:1.5rem !important;
    height: 4.5rem !important;
}

.latitude-input{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 2px solid #69B5D2 !important; 
    border-radius: 7px;
    color : #69B5D2;
    margin: 1.5rem 1.8rem auto !important;
    height: 5rem !important;
    width: 16rem !important;
    padding:1.5rem !important;
    position: absolute;
}

.longitude-input{
    font-size: 2rem !important;
    background-color: #EDF5F8;
    border: 2px solid #69B5D2 !important; 
    border-radius: 7px;
    color : #69B5D2;
    margin: 1.5rem 2.1rem auto !important;
    height: 5rem !important;
    width: 16rem !important;
    padding:1.5rem !important;
    position: absolute;
}

.maps-button{
    width: 91.5% !important;
    background-color: #1BAEC9;
    color:white;
    border:none;
    font-size: 2rem !important;
    border-radius: 7px;
    height: 4.8rem;
    margin:2rem auto;
    text-align: center;
    line-height: 4.8rem;
}

 .maps-wrapper .set-location-button{
    width: 20rem !important;
    background-color: #1BAEC9;
    color:white;
    border:none;
    font-size: 2rem !important;
    border-radius: 7px;
    height: 5rem;
    margin: 1.5rem 0rem auto !important;
    text-align: center;
    line-height: 3.8rem;
}
.maps-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 5rem;
}

/* .form-page-content .open-maps .maps-wrapper .form-control.is-valid, .was-validated .form-control:valid{
    border-color:transparent;
    background-image: none;
} */
.form-page-content .form2-button{
    background-color: #1BAEC9;
    color: white;
    padding: 1rem !important;
    margin-top: 3.5rem;
    width: 92%;
    border-radius: 10px !important;
    font-size: 1.9rem !important;
    margin-left: 2.3rem;
}

.form-page-group label{
    display: block !important;
    text-align: left;
    margin-left: 2.5rem;
    font-size: 1.8rem;
}
.form-page-wrapper .label{
    display: block !important;
    text-align: left;
    margin-left: -2.1rem;
    font-size: 1.8rem;
}

.form-page-content .btn2{
    margin-left: 1.2em;

}
.form-page-content .invalid-feedback{
    margin-top: -1rem !important;
    margin-left: -20rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    text-align: center !important;
    
}

.form-page-content .valid-feedback{
    margin-top: -1rem !important;
    margin-left: -23.5rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    text-align: center !important;
}

.form-page-wrapper .invalid-feedback{
    margin-top: -1rem !important;
    margin-left: -5.5rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    text-align: center !important;
}


.form-page-wrapper .valid-feedback{
    margin-top: 2rem !important;
    margin-left: -10rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    text-align: center;
}

.form-page-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 2.1rem;
    margin-right: 1.4rem;
}
.form-page-group select{
    font-size: 1.9rem !important;
    background-color: #EDF5F8;
    border: 3px solid #69B5D2; 
    border-radius: 7px;
    color : #69B5D2;
    padding-left: 1.5rem !important;
    margin: 1.5rem auto;
    margin-left: 2.5rem;
    height: 4.3rem !important;
    width: 56.5rem;
}
.form-page-group select option{
    font-size: 0.9rem;
}
.form-kk .input-select option::-webkit-input-placeholder{
    font-size: 1.7rem !important;
    color : #69B5D2;
    font-style: italic;
}

.first-option::placeholder{
    font-style: italic;
}
.open-maps{
    width: 90%;
    margin: 3rem auto;

}

@media (min-width:900px){
    .form-page-content{
        font-size: 1.3rem;
        /* padding: 2rem 8rem 0 8rem; */
        background-color: #EDF5F8;
        height: 110vh;
    }
    
    .form-page-content .input-text{
        font-size: 1.3rem;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 7px;
        color : #69B5D2;
        padding: 1.5rem;
        width: 92%;
    }
    .form-page-content .input-text2{
        font-size: 1.3rem;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 7px;
        color : #69B5D2;
        padding: 1.5rem;
        width: 92%;
    }
    
    .form-page-content .input-text::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic;
        font-size: 1.2rem;
    }
    
    .form-page-content .input-text2::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic;
        font-size: 1.2rem;
    }
    
    .form-page-content button{
        background-color: #1BAEC9;
        color: white;
        padding: 0.5rem;
        margin-top: 2rem;
        width: 92%;
        border-radius: 5px;
        font-size: 1.2rem;
    }
    
    .form-page-content label{
        display: block !important;
        margin-bottom: -1rem !important;
        margin-left: 2.8rem;
    }
    
    .form-page-content .btn2{
        margin-left: 4%;
    }
    .form-page-content .invalid-feedback{
        margin-top: -1rem;
        font-size: 14px;
        margin-left: -23.5rem;
        margin-bottom: 1.5rem;
    }
    .form-page-content .valid-feedback{
        margin-top: -1rem;
        font-size: 14px;
        margin-left: -25.5rem;
        margin-bottom: 1.5rem;
        
    }
}

@media(min-width:1024px){
    .form-page-content .invalid-feedback{
        margin-top: -1rem;
        font-size: 1.3rem !important;
        margin-left: -22.5rem !important;
        margin-bottom: 1.5rem;
    }
    .form-page-wrapper .invalid-feedback{
        margin-top: -1rem;
        margin-left: -7.1rem !important;
        font-size: 1.3rem !important;
        margin-bottom: 1.5rem;
    }
    .form-page-wrapper .valid-feedback{
        margin-top: -1rem;
        margin-left: -10rem !important;
        font-size: 1.3rem !important;
        margin-bottom: 1.5rem;
    }
}
@media (min-width:1280px){
    .form-page-content{
        width: 40%;
        font-size: 1rem !important;
        padding : 2rem 0 0 2rem;
        background-color: #EDF5F8;
        height: 105vh !important;
        margin: 2.8rem auto;
        border-radius: 10px;
        box-shadow: 0px 5px 20px 2px rgba(173, 171, 171, 0.8);
    }
    
    .form-page-content .input-text{
        font-size: 14px !important;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 3px;
        color : #69B5D2;
        padding: 1rem !important;
        width: 100% !important;
        margin-left: 0rem;
        margin-right: -5rem;
    }
    .form-page-content .input-text2{
        font-size: 14px;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2; 
        border-radius: 3px;
        color : #69B5D2;
        padding: 1rem !important;
        width: 120%;
        margin-left: 0rem;
        margin-right: -5rem;
    }

    .form-page-content .input-text::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic; 
        font-size:14px !important;
    }
    .form-page-content .input-text2::-webkit-input-placeholder{
        color : #69B5D2;
        font-style: italic; 
        font-size:12px;
    }
    .form-page-content .input-double{
        font-size: 14px !important;
        background-color: #EDF5F8;
        border: 2px solid #69B5D2 !important; 
        border-radius: 3px !important;
        color : #69B5D2;
        margin: 1.5rem auto;
        width: 15.2rem !important;
        padding:1rem !important;
        margin-left: -2rem;
    }
    .form-page-content .input-double::-webkit-input-placeholder{
        font-size: 14px !important;
        color : #69B5D2;
        font-style: italic;
    }
    
    
    .form-page-content button{
        background-color: #1BAEC9;
        color: white;
        padding: 0.5rem !important;
        margin-top: 1.5rem;
        margin-left: -2.2rem !important;
        width: 92%;
        border-radius: 5px;
        font-size: 15px !important;
    }
    
    .form-page-content label{
        text-align: left;
        margin-left: 0rem;
        margin-bottom: -1rem;
        font-size: 1rem;
    }
    .form-page-content .btn2{
        width: 92%;
        margin-left: 0rem !important;
    }

    .form-page-group{
        text-align: left;
    }
    .form-page-content .invalid-feedback{
        margin-top: -1rem;
        margin-left: 0rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }
    .form-page-content .valid-feedback{
        margin-top: -1rem;
        margin-left: 0rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }
    .form-page-wrapper .invalid-feedback{
        margin-top: -1rem;
        margin-left: -4.1rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }

    .form-page-wrapper .input-control .invalid-feedback{
        margin-top: -1rem;
        margin-left: -5rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }
    .form-page-wrapper .valid-feedback{
        margin-top: -1rem;
        margin-left: -6.2rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }
    .form-page-wrapper .input-control .valid-feedback{
        margin-top: -1rem;
        margin-left: -7rem !important;
        font-size: 14px !important;
        margin-bottom: 1.5rem;
    }
    
}

/* RESPONSIVE */

/* DROPDOWN NAVBAR */
.household-dropdown .dropdown-menu{
    width: 15rem !important;
    padding: 18px 0 0 22px;
    font-size: 1.7rem !important;
    border:none;
    border-radius: 10px;
    height: 8rem;
    font-family: Helvetica, sans-serif;
    position: absolute;
    left: 21rem !important;
    top: -1.8rem !important;
}

.household-dropdown .menu-icon{
    color:white;
    font-size: 4rem;
    margin-left: 38rem;
    position: absolute;
    top: -1.5rem !important;
    left: -4rem !important;
  }