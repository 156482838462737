.string-question{
    margin-bottom: 4rem !important;
    margin-top: 2rem !important;
    display: block !important;
}
.question-text{
    margin-left: 2rem !important;
    margin-right: 3rem;
    font-size: 2.2rem !important;
    text-align: left;
}
.string-question .string-wrap{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-left: 2rem !important;
  text-align: left;
  font-size: 1.5rem;
  }
  .string-choice{
      font-size: 2rem !important;
  }

  .string-field{
    border: none;
    border-bottom: 3px solid blue;
  }

  .string-field:focus{
    width: 10vh;
  }
  .container {
    width: 200px;
    position: relative;
   }
  .string-question input[type="text"] { 
    width: 30vh; 
    margin-left: -1rem;
    font-size: 1.8rem !important;
    font-family: helvetica;
  }
   .string-question .input {
    border: 0;
    padding: 10px 0;
    border-bottom: 5px solid #ccc;
   }
   .string-question .input ~ .border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #08449C;
   }
   .string-question .input:focus ~ .border {
    width: 30vh;
    transition: 0.5s;
   }