.single-question{
  margin-bottom: 2rem !important;
  display: block;
}

.single-button-wrapper{
  /* position: absolute; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-left: 2rem !important;
  text-align: left;
  font-size: 1.5rem;
}

.single-question .single-options{
  margin-bottom: 2rem !important;
}
.question-text{
  margin-left: 2rem !important;
  margin-right: 3rem;
  font-size: 2.2rem !important;
  text-align: left;
}

.choice-wrap{
  text-align: left;
  margin-left: 1rem !important;
}

.choice-answer{
  font-size: 2rem;
  color:black;
  margin-left: 3rem !important;
}

.single-button-wrapper [type="radio"]:checked,
.single-button-wrapper [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.single-button-wrapper [type="radio"]:checked + label,
.single-button-wrapper [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    /* line-height: 20px; */
    display: inline-block;
    color: #666;
}
.single-button-wrapper [type="radio"]:checked + label:before,
.single-button-wrapper [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: -3rem !important;
    top: 0.5rem !important;
    width: 3rem;
    height: 3rem;
    border: 2px solid #999;
    border-radius: 100%;
    background: #fff;
    scale: 2;
}
.single-button-wrapper [type="radio"]:checked + label:after,
.single-button-wrapper [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 1.7rem !important;
    height: 1.7rem !important;
    background: #1BAEC9;
    position: absolute;
    top: 1.1rem !important;
    left: -2.3rem !important;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.single-button-wrapper [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.single-button-wrapper [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}