
.table-navbar{
    background: radial-gradient(farthest-corner at 90% 15%, rgba(28, 170, 197), rgba(8, 68, 156) );
    height:13rem !important;
    font-family: helvetica;
}

.table-brand{
    color: white;
    font-size: 1.9rem !important;
    font-family: helvetica;
}

.navbar-body  .wrap3 .dropdown-menu{
    width: 15rem !important;
    margin-top: -5rem !important;
    margin-left: 1.5rem !important;
    padding: 18px 0 0 22px;
    font-size: 1.7rem !important;
    border:none;
    border-radius: 10px;
    height: 8rem;
    font-family: Helvetica, sans-serif;
}
.search-icon{
    color:white;
    font-size: 2.5rem;
    position:absolute;
    left: 53rem;
    top: 3.3rem;
}
.menu-icon{
  color:white;
  font-size: 4rem;
  margin-left: 14rem;
}

.swal-title{
    font-size: 1.5rem !important;
}
.swal-text{
    font-size: 1.3rem !important;
}
.search-label{
    width: 33rem !important;
    position: absolute !important;
    left: 23rem !important;
    top: 2.5rem !important;
}

.search-label .form-control{
    font-size: 1.8rem !important;
    border-radius: 5rem !important;
    font-style: italic !important;
    padding: 2.2rem !important;
    border: none !important;
    background: rgba(255, 255, 255, .5);
    margin-left: 1rem !important;
  }
  
  .search-label input::-webkit-input-placeholderl{
    color:white !important;
  }
.wrap1{
    width: 18rem;
    margin-top: 0.5rem !important;
}
.wrap3{
  margin-top: -1rem !important;
  margin-left: 0rem !important;
}
.wrap5{
    margin-left: 2rem;
    width: 15rem !important;
}
.wrap6{
    margin-left: 7rem;
}

.head-table{
    margin-top: 0rem !important;
    width: 100% !important;
}

.head-table tr td{
    font-size: 1.5rem;
}
