@import url('https://fonts.googleapis.com/css?family=Roboto');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading {
  position: fixed;
  top: 0; right: 0;
  bottom: 0; left: 0;
  /* background: #fff; */
  background: #ffffffa6;
  z-index: 99999999;
}
.loaders {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: .8em solid rgba(218, 219, 223, 1);
  border-left: .8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
}
.loaders, .loaders:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

.loading-soal {
  animation: fadeIn .7s infinite alternate;
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 60%;
  left: 50%;
  margin: -100px 0 0 -150px;
}

@keyframes fadeIn { 
  from { opacity: 0; } 
}

@keyframes spin {
0% {
  transform: rotate(360deg);
}
100% {
  transform: rotate(0deg);
}
}