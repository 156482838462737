.date-question{
  margin-bottom: 4rem !important;
  margin-top: 2rem !important;
  display: block !important;
}

.date-question .react-datepicker-popper{
  position: static;
  left: 0% !important;
  top: 1rem !important;
} 

.react-datepicker__triangle{
  display: none !important;
}

.date-wrapper{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-left: 2rem !important;
  text-align: left;
  font-size: 1.5rem;
}

.date-question .react-datepicker-wrapper input{
  padding: 1rem;
  font-size: 2rem !important;
  border: 1px solid black;
  width: 13rem;
}

/* DATE PICKER */

.date-question .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  font-size: 1.8rem !important;
}

.date-question .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  font-size: 23px !important;
  width: 3.3rem !important;
  height: 3.3rem !important;
  line-height: 2.3rem !important;
} 
