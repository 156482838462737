/* Position and sizing of burger button */
/* .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  } */
  
  /* Color/shape of burger icon bars */
  /* .bm-burger-bars {
    background: #373a47;
  } */
  
  /* Color/shape of burger icon bars on hover*/
  /* .bm-burger-bars-hover {
    background: #a90000;
  }
   */
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  .close-button{
    width: 40px !important;
    float:right !important;
    margin-top: -7% !important;
    margin-right: 3% !important;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed !important;
    height: 100% !important;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #1277B0;
    font-size: 2em !important;
    width: 45vh !important;
    height: 110% !important;
    padding-top: 5rem !important;
    padding-left: 10rem !important;
    margin-top: -10rem !important;
    margin-left: -8rem !important;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: white;
    padding: 0.8em !important;
    text-align: left;
    font-size: 2rem !important;
  }
  
  /* Individual item */
  /* .bm-item {
    display: inline-block;
    color: white;
    font-family: 'PT Sans', sans-serif;
    font-size: 25px;
    line-height: 1.5em;
  } */
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    margin-top: -4em !important;
    margin-left: -2em;
  }

  .list-options{
      color: white !important;
      font-family: 'PT Sans', sans-serif !important;
      font-size: 2.1rem !important;
      line-height: 2.3em !important;
      display: block !important;
      text-align: left;
  }
  .child-options{
    color: white !important;
    font-family: 'PT Sans', sans-serif !important;
    font-size: 2rem !important;
    line-height: 1.8em;
    display: block;
    text-align: left;
    margin-left: 8%;
}

  .sidebar-title{
    color: white;
    text-align: left;
    font-size: 2.5rem !important;
    font-weight: bold;
  }

  .sidebar-menu .title-underline{
    width: 85%;
    border-bottom: 3px solid white;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .logout{
    font-size: 2rem !important;
    color: white;
    text-align: left !important;
    position: absolute !important;
    top: 50% !important;
  }

  @media (min-width:900px){
    .bm-menu-wrap {
      position: fixed;
      margin-left: -2.3rem;
      margin-top: 4vh;
    }
    
    /* General sidebar styles */
    .bm-menu {
      background: #1277B0;
      /* padding: 2.5em 1.5em 0; */
      font-size: 1.15em;
      width: 65%;
      height: 104vh;
      margin-left: 0.1rem;
      padding-left: 1.5rem;
      padding-top: 3rem;
    }
    .list-options{
      color: white;
      font-family: 'PT Sans', sans-serif;
      font-size: 1.3rem;
      line-height: 2em;
      display: inline-block;
      text-align: left;
  }
  .list-options:hover{
    color: white;
}
  .list-options2{
    color: white;
    font-family: 'PT Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 1.8em;
    display: inline-block;
    text-align: left;
    margin-left: 8%;
}
.list-options2:hover{
  color: white;
}

  .sidebar-title{
    color: white;
    text-align: left;
    font-size: 2rem;
  }

  .sidebar-menu .title-underline{
    width: 80%;
    border-bottom: 1px solid white;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .logout{
    font-size: 1.2rem;
    color: white;
    margin-top: 35vh;
    text-align: left;
  }
  .logout:hover{
    color: midnightblue;
    text-decoration: none;
  }
  .close-button{
    width: 30px;
    float:right;
    margin-top: -6%;
  }
  } 
  @media (min-width:1024px){
    /* Position and sizing of burger button */
    /* .bm-burger-button {
        position: fixed;
        width: 36px;
        height: 30px;
        left: 36px;
        top: 36px;
      } */
      
      /* Color/shape of burger icon bars */
      /* .bm-burger-bars {
        background: #373a47;
      } */
      
      /* Color/shape of burger icon bars on hover*/
      /* .bm-burger-bars-hover {
        background: #a90000;
      }
       */
      /* Position and sizing of clickable cross button */
      .bm-cross-button {
        height: 24px;
        width: 24px;
      }
      
      /* Color/shape of close button cross */
      .bm-cross {
        background: #bdc3c7;
      }
    
      .close-button{
        width: 30px;
        float:right;
        margin-top: -6%;
      }
      
      /*
      Sidebar wrapper styles
      Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
      */
      .bm-menu-wrap {
        position: fixed;
        margin-left: -2.3rem;
        margin-top: 4vh;
      }
      
      /* General sidebar styles */
      .bm-menu {
        background: #1277B0;
        /* padding: 2.5em 1.5em 0; */
        font-size: 1.15em;
        width: 65%;
        height: 104vh !important;
        margin-left: 0.1rem;
        padding-left: 2rem;
        padding-top: 4rem;
      }
      
      /* Morph shape necessary with bubble or elastic */
      .bm-morph-shape {
        fill: #373a47;
      }
      
      /* Wrapper for item list */
      /* .bm-item-list {
        color: white;
        padding: 0.8em;
        text-align: left;
      } */
      
      /* Individual item */
      /* .bm-item {
        display: inline-block;
        color: white;
        font-family: 'PT Sans', sans-serif;
        font-size: 25px;
        line-height: 1.5em;
      } */
      
      /* Styling of overlay */
      .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
        height: 102vh !important;
      }
    
      .list-options{
          color: white;
          font-family: 'PT Sans', sans-serif;
          font-size: 1.3rem;
          line-height: 2em;
          display: inline-block;
          text-align: left;
      }
      .list-options:hover{
        color: white;
    }
      .list-options2{
        color: white;
        font-family: 'PT Sans', sans-serif;
        font-size: 1.2rem;
        line-height: 1.8em;
        display: inline-block;
        text-align: left;
        margin-left: 8%;
    }
    .list-options2:hover{
      color: white;
    }
    
      .sidebar-title{
        color: white;
        text-align: left;
        font-size: 2rem;
      }
    
      .sidebar-menu .title-underline{
        width: 80%;
        border-bottom: 1px solid white;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    
      .logout{
        font-size: 1.2rem;
        color: white;
        margin-top: 35vh;
        text-align: left;
      }
      .logout:hover{
        color: midnightblue;
        text-decoration: none;
      }
    
      }
    

  @media (min-width:1280px){
/* Position and sizing of burger button */
/* .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  } */
  
  /* Color/shape of burger icon bars */
  /* .bm-burger-bars {
    background: #373a47;
  } */
  
  /* Color/shape of burger icon bars on hover*/
  /* .bm-burger-bars-hover {
    background: #a90000;
  }
   */
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  .close-button{
    width: 30px;
    float:right;
    margin-top: -7%;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    margin-left: -2.3rem;
    margin-top: 6vh;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #1277B0;
    /* padding: 2.5em 1.5em 0; */
    font-size: 1.15em;
    width: 45% !important;
    height: 105vh !important;
    margin-left: 0.1rem;
    padding-left: 1rem;
    padding-top: 6rem;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  /* .bm-item-list {
    color: white;
    padding: 0.8em;
    text-align: left;
  } */
  
  /* Individual item */
  /* .bm-item {
    display: inline-block;
    color: white;
    font-family: 'PT Sans', sans-serif;
    font-size: 25px;
    line-height: 1.5em;
  } */
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    height: 102vh !important;
  }

  .list-options{
      color: white;
      font-family: 'PT Sans', sans-serif;
      font-size: 1.3rem;
      line-height: 1.8em;
      display: inline-block;
      text-align: left;
  }
  .list-options:hover{
    color: white;
}
  .list-options2{
    color: white;
    font-family: 'PT Sans', sans-serif;
    font-size: 1.1rem;
    line-height: 1.8em;
    display: inline-block;
    text-align: left;
    margin-left: 8%;
}
.list-options2:hover{
  color: white;
}

  .sidebar-title{
    color: white;
    text-align: left;
    font-size: 1.8rem;
  }

  .sidebar-menu .title-underline{
    width: 75%;
    border-bottom: 3px solid white;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .logout{
    font-size: 16px;
    color: white;
    margin-top: 52vh;
    text-align: left;
  }
  .logout:hover{
    color: midnightblue;
    text-decoration: none;
  }

  }
