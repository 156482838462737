.regis-form {
    background-image: url(./background.png);
    background-color: #005083;
    width: 100%;
    height: 100vh;
    margin: auto;
    background-repeat: no-repeat;
    position: fixed;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}
.title-regis{
    margin-top: 12rem !important;
    margin-left: 11em !important;
}
.form-group-regis .username-regis, .email-regis, .password-regis{
    margin: 10px;
}
.is-danger-regis{
    font-size: 1.5rem;
    color:white;
    margin-left: 0rem;
    margin-top: 7px;
}
.title-regis p{
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    color: #ffffff;
    font-size: 6.5rem;
    margin-bottom: 0.5rem;
}

.form-group-regis {
    margin-top: 12em;
    text-align: left;
    margin-left: 10rem;
}

.form-group-regis input {
    width: 40rem !important;
    height: 7rem;
    padding: 2rem !important;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.7rem !important;
    font-weight: 300;
    color: #0C3265;
    border: none;
    margin-bottom: -5px;
}
.form-group-regis input::-webkit-input-placeholder {
    color:#005083;
    font-size: 1.7rem;
}

.regis .button-regis {
    margin-top: 7rem !important;
    margin-left: 0.3em !important;
    width: 40rem;
    height: 6rem;
    border: none;
    background-color: #1BAEC9;
    font-size: 2rem;
    color: #ffffff;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border:none;
    border-radius: 10px;
}

.login-regis-page p {
    color: #ffffff;
    margin-left: 1rem !important;
    font-size: 1.6rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 0.75rem;

}

.text-bold{
    font-weight: bold;
}

.form-group-regis .invalid-feedback{
    margin-top: 10px;
    margin-left: -2rem;
    color:white;
}
.form-group-regis .valid-feedback{
    margin-top: 10px;
    margin-left: -2rem;
    color:white;
}

@media (min-width:900px){
    .regis-form {
        background-image: url(./background.png);
        background-color: #005083;
        width: 100%;
        height: 150vh;
        margin-top: -2rem !important;
        position: absolute;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
    }
    .form-group-regis .invalid-feedback{
        margin-top: 10px;
        margin-left: 0rem;
        font-size: 1.2rem;
        color:white;
    }
    .form-group-regis .valid-feedback{
        margin-top: 10px;
        margin-left: 0rem;
        font-size: 1.2rem;
        color:white;
    }
}

@media (min-width:1024px){
    .regis-app {
        background-image: url(./background.png);
        opacity: 20%;
        width: 100%;
        height: 100vh;
        padding-top: 2.5rem;
        background-repeat: no-repeat;
        position: relative;
        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
    }
    
    .regis-form {
        background-image: url(./background.png);
        background-color: #005083;
        width: 30rem;
        height: 50rem;
        margin-top: 2rem !important;
        margin-bottom: 3.5rem;
        margin-left:18rem;
        position: absolute;
        background-size: cover;
    }
    
    .title-regis {
        margin-top: 6em !important;
        margin-left: 5em !important;
    }

    .title-regis p{
        font-size: 3rem;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: left;
        color: #ffffff;
    }
    .is-danger-regis{
        font-size: 12px;
        margin-top: 7px;
        color:white;
        margin-left: -1rem;
        margin-bottom: -5px;
    }
    .form-group-regis {
        margin-top: 5rem;
        text-align: left;
        margin-left: 5rem;
    }
    
    .form-group-regis input {
        width: 21rem !important;
        height: 1rem !important;
        margin-left: -1rem;
        padding-left: 10px !important;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px !important;
        font-weight: 300;
        color: #0C3265;
        border: none;
        margin-bottom: -7px;
    }
    .form-group-regis input::-webkit-input-placeholder {
        padding-left: 5px !important;
        color:#005083;
        font-size: 12px !important;
    }
    
    .regis .button-regis {
        margin-top: 3rem !important;
        margin-left: -0.4rem !important;
        width: 21rem;
        height: 3rem;
        border: none;
        background-color: #1BAEC9;
        font-size: 16px;
        color: #ffffff;
        font-family: Arial, Helvetica, sans-serif;
        border:none;
        border-radius: 5px;
    }
    
    .login-regis-page p {
        color: #ffffff;
        margin-left: -0.3rem !important;
        font-size: 11px;
        margin-top: 0.75rem;
    
    }
    .text-bold{
        font-weight: bold;
    }
    .form-group-regis .invalid-feedback{
        margin-top: 10px;
        margin-left: -1rem;
        color:white;
    }
    .form-group-regis .valid-feedback{
        margin-top: 10px;
        margin-left: -1rem !important;
        color:white;
    }
}

@media (min-width:1280px){
    .regis-app {
        background: url(./background.png);
        opacity: 20%;
        width: 100%;
        height: 100vh;
        padding-top: 2.5rem;
        background-repeat: no-repeat;
        position: fixed;
        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
    }
    
    .regis-form {
        background: url(./background.png);
        background-color: #005083;  
        width: 24rem;
        height: 46rem;
        margin-top: 4rem !important;
        margin-bottom: 3.5rem;
        margin-left:32rem;
        background-size: cover;
    }
    .is-danger-regis{
        font-size: 12px;
        color:white;
        margin-left: -2rem;
        margin-bottom: -5px;
    }
    .title-regis {
        margin-top: 4rem !important;
        text-align: left !important;
        margin-left: 4rem !important;
    }
    .title-regis p{
        font-size: 2.8rem;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        text-align: left;
        color: #ffffff;
    }

    .form-group-regis{
        margin-top: 4rem;
    }
    
    .form-group-regis input {
        width: 17rem !important;
        height: 3rem !important;
        margin-left: -2.2rem !important;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px !important;
        color: #0C3265;
        border: none;
        margin-bottom: -8px !important  ;   
    }

    .form-group-regis input::-webkit-input-placeholder {
        color:#005083;
        font-size: 12px !important;
    }
    
    .regis .button-regis {
        margin-top: 2rem !important;
        margin-left: -1.7rem !important;
        width: 17rem;
        height: 3rem;
        border: none;
        background-color: #1BAEC9;
        font-size: 13px;
        color: #ffffff;
        font-family: Arial, Helvetica, sans-serif;
        border:none;
        border-radius: 5px;
    }
    
    .login-regis-page p {
        color: #ffffff;
        margin-left: -1.5rem !important;
        font-size: 12px;
        margin-top: 0.75rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    .text-bold{
        font-weight: bold;
    }
    .form-group-regis .invalid-feedback{
        margin-top: 10px;
        margin-left: -2rem;
        color:white;
    }
    .form-group-regis .valid-feedback{
        margin-top: 10px;
        margin-left: -2rem;
        color:white;
    }
}