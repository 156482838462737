.numeric-question{
    margin-bottom: 6rem !important;
    display: block;
}
.question-text{
    margin-left: 2rem !important;
    margin-right: 3rem;
    font-size: 2.2rem !important;
    text-align: left;
}
.in-decrement{
    margin-top: -1rem !important;
    margin-left: -3rem !important;
    margin-bottom: -7rem;
}
.btn-in-decrement{
    width: 60px !important;
    height: 60px !important;
    background-color: #1BAEC9;
    border:none !important;
    border-radius: 50% !important;
    line-height: 10px;
    padding-bottom: 10px !important;
    font-size: 2rem;
    color: white;
    font-weight: bold;
    display: inline-block;
}
.in-decrement input{
    width: 9rem !important;
    height: 3.5rem !important;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem !important;
    text-align: center;
    font-size: 2.3rem !important;
    border: 2px solid #1BAEC9;
}

.numeric-question .button-wrapper{
    /* position: absolute; */
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 5rem !important;
    text-align: left;
    font-size: 1.5rem;
}
.choice-wrap{
    text-align: left;
    margin-left: -1.5rem !important;
  }
